import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {  
    LinearProgress, Divider, Card, CardHeader, CardContent, CardActions,
    Button
} from "@material-ui/core";
import { ConfirmDialog } from '../components/ConfirmDialog';
import { API } from "aws-amplify";
import { Candidate } from '../../src/CandidateTypes';
import { FormatDateTime } from '../utils';

const useStyles = makeStyles(() =>
  createStyles({
    progress: {
        backgroundColor: "#ff9800"
    },
    progressBackground: {
        backgroundColor: "#ffe0b2"
    },
    card: {
        marginTop: 16
    }
  })
);

interface Props {
    candidate: Candidate | undefined
}

interface DataPrivacyInfo {
    pk: string;
    sk: string;
    pk1: string;
    sk1: string;
    requestedAt: string
}

export default function DataPrivacy({ candidate }: Props) {
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmPrivacyDialog, setOpenConfirmPrivacyDialog] = useState<boolean>(false);
    const [privacyLoading, setPrivacyLoading] = useState<boolean>(false)
    const [privacyError, setPrivacyError] = useState<string>()
    const [disablePrivacy, setDisablePrivacy] = useState<boolean>(false)
    const [privacyRequest, setPrivacyRequest] = useState<DataPrivacyInfo>()
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [deleteError, setDeleteError] = useState<string>()
    const [disableDelete, setDisableDelete] = useState<boolean>(false)
    const [deleteRequest, setDeleteRequest] = useState<DataPrivacyInfo>()

    useEffect(() => {
        if(candidate){
            setLoading(true)
        API.get("", `/candidates/${candidate.username}/data-privacy/request`, "")
        .then((response: Array<DataPrivacyInfo>) => {
            if(response) {
                response.forEach((i: DataPrivacyInfo) => {
                    if(i.sk.includes("PRIVACY")) {
                        setDisablePrivacy(true)
                        setPrivacyRequest(i)
                    }
                    if(i.sk.includes("DELETE")) {
                        setDisableDelete(true)
                        setDeleteRequest(i)
                    }
                })
            }
            setLoading(false)
        })
        .catch((error: any) => {
            setLoading(false)
            console.log("Error: fetching data privacy data", error)
        })

        }
        
    }, [candidate])

    const getDataPrivacyInfo = () => {
        if(candidate) {
            setLoading(true)
            API.get("", `/candidates/${candidate.username}/data-privacy/request`, "")
            .then((response: Array<DataPrivacyInfo>) => {
                if(response) {
                    response.forEach((i: DataPrivacyInfo) => {
                        if(i.sk.includes("PRIVACY")) {
                            setDisablePrivacy(true)
                            setPrivacyRequest(i)
                        }
                        if(i.sk.includes("DELETE")) {
                            setDisableDelete(true)
                            setDeleteRequest(i)
                        }
                    })
                }
                setLoading(false)
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("Error: fetching data privacy data", error)
            })
        }
    }

    const privacyOnSubmit = () => {
        if(candidate) {
            setPrivacyLoading(true)
            let params = {
                body: {
                    id: candidate.username,
                    firstName: candidate.firstName,
                    surname: candidate.surname,
                    email: candidate.email,
                    type: "privacy"
                }
            }
            API.post("", "/candidates/data-privacy/request", params)
            .then(() => {
                setPrivacyLoading(false)
                setOpenConfirmPrivacyDialog(false)
                getDataPrivacyInfo()
            })
            .catch((error: any) => {
                setPrivacyLoading(false)
                setPrivacyError("Error: request failed")
                console.log("Error: unable to request data privacy", error)
            })
        }
    }

    const deleteOnSubmit = () => {
        if(candidate) {
            setDeleteLoading(true)
            let params = {
                body: {
                    id: candidate.username,
                    firstName: candidate.firstName,
                    surname: candidate.surname,
                    email: candidate.email,
                    type: "delete"
                }
            }
            API.post("", "/candidates/data-privacy/request", params)
            .then(() => {
                setDeleteLoading(false)
                setOpenConfirmDeleteDialog(false)
                getDataPrivacyInfo()
            })
            .catch((error: any) => {
                setDeleteLoading(false)
                setDeleteError("Error: request failed")
                console.log("Error: unable to request data delete", error)
            })
        }        
    }

    return (
        <div>
            <h1 style={{marginBottom: 16}}>Data & Privacy</h1>
            {loading &&
                <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}} />
            }
            <Divider />

            {!loading && 
                <div>
                    <Card raised className={classes.card}>
                        <CardHeader title="Data Privacy Request" />
                        <CardContent>
                            <p>By requesting your data, you will be able to see all information stored about your account.</p>
                        </CardContent>
                        <CardActions style={{display: "flex", backgroundColor: "#f4f4f4"}}>
                            <div style={{flexGrow: 1}}></div>
                            <div>
                                {privacyRequest &&
                                    <span>Requested At: <strong>{FormatDateTime(privacyRequest.requestedAt)}</strong></span>
                                }
                                <Button style={{marginLeft: 8}} variant="contained" color="primary" onClick={() => setOpenConfirmPrivacyDialog(true)} disabled={disablePrivacy}>
                                    Request
                                </Button>
                            </div>
                        </CardActions>
                    </Card>

                    <Card raised className={classes.card}>
                        <CardHeader title="Data Delete Request" />
                        <CardContent>
                            <p>You're data is collected and stored in Lowie Recruitments encrypted servers, we collect this data to ensure that we offer you jobs that are most suited to you. Authorised Lowie representatives will have access to this data when assessing you for roles. If you wish to have your Data deleted from our servers please email as us <a href="mailto:Data@lowie.co.nz?subject=Delete Data Request">Data@lowie.co.nz</a>  </p>
                            <p><a href='https://lowie.co.nz/privacy-policy/' target='_blank'>https://lowie.co.nz/privacy-policy/</a></p>
                        </CardContent>
                        {/* <CardActions style={{display: "flex", backgroundColor: "#f4f4f4"}}>
                            <div style={{flexGrow: 1}}></div>
                            <div>
                                {deleteRequest &&
                                    <span>Requested At: <strong>{FormatDateTime(deleteRequest.requestedAt)}</strong></span>
                                }
                                <Button style={{marginLeft: 8}} variant="contained" color="primary" onClick={() => setOpenConfirmDeleteDialog(true)} disabled={disableDelete}>
                                    Request
                                </Button>
                            </div>
                        </CardActions> */}
                    </Card>
                </div>
            }

            <ConfirmDialog
                open={openConfirmPrivacyDialog}
                handleDialog={setOpenConfirmPrivacyDialog}
                dialogTitle="Data Privacy Request"
                dialogDescription="Are you sure you want to request all data collected from your account?"
                onSubmit={privacyOnSubmit}
                loading={privacyLoading}
                setLoading={setPrivacyLoading}
                error={privacyError}
                setError={setPrivacyError} />

            <ConfirmDialog
                open={openConfirmDeleteDialog}
                handleDialog={setOpenConfirmDeleteDialog}
                dialogTitle="Data Delete Request"
                dialogDescription="Are you sure you want to request to delete all data collected from your account?"
                onSubmit={deleteOnSubmit}
                loading={deleteLoading}
                setLoading={setDeleteLoading}
                error={deleteError}
                setError={setDeleteError} />
        </div>
    )
}

