import React, { useState } from 'react'
import {
    Link, Button
} from "@material-ui/core";
import { Candidate } from '../../CandidateTypes';
import { API } from "aws-amplify";

interface Props {
    incrementActiveStep: () => void;
    activeStep: number
    candidate: Candidate | undefined
}

export default function EmploymentContract({ incrementActiveStep, activeStep, candidate }: Props) {

    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = () => {
        if(candidate) {
            setLoading(true)
            let params = {
                body: {
                    data: {},
                    step: activeStep,
                    id: candidate.username
                }
            }
            API.post("", '/candidates/jobPlacement/update', params)
            .then(() => {
                setLoading(false)
                incrementActiveStep()
            })
            .catch((error: any) => {
                console.log("Error: submitting emplyoment contract", error)
                setLoading(false)
            })
        }
    }

    return (
        <div>
            <h3 style={{marginBottom: 16}}>Employment Contract</h3>
            <div>
                <p>By clicking next you agree to the terms and conditions stated in the Employment Contract below.</p>
                <Link href="#/portal/job-placement">
                    Employment Contract
                </Link>
            </div>

            <div>
                <Button style={{marginTop: 16}} variant="contained" color="primary" onClick={() => onSubmit()} disabled={loading}>
                    Next
                </Button>
            </div>
        </div>
    )
}
