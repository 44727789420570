import React, { useEffect, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button, FormControl, FormLabel, TextField
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form";
import { AdditionalInfo, Candidate } from '../../CandidateTypes';
import { API } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: theme.spacing(3),
        width: "100%"
    },
    section: {
        marginBottom: 16
    },
    label: {
        marginBottom: 6
    }
  }),
);

interface Props {
    activeStep: number
    incrementActiveStep: () => void;
    candidate: Candidate | undefined
}

export default function NextOfKin({ activeStep, candidate, incrementActiveStep }: Props) {

    const classes = useStyles()

    const { handleSubmit, control, errors, reset } = useForm<AdditionalInfo>({
        shouldUnregister: false
    });
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if(candidate) {
            setLoading(true)
            API.get("", `/candidates/${candidate.username}/registrationStep/1`, "")
            .then((response: AdditionalInfo) => {
                reset(response)
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching candidate additional info", error)
                setLoading(false)
            })
        }

    }, [candidate])

    const onSubmit = (data: AdditionalInfo) => {
        if(data && candidate) {
            setLoading(true)
            var params = {
                body: {
                    data: data,
                    step: activeStep,
                    id: candidate.username
                }
            }
            API.post("", `/candidates/jobPlacement/update`, params)
            .then(() => {
                setLoading(false)
                incrementActiveStep()
            })
            .catch((error: any) => {
                console.log("Error: updating candidate additional info", error)
                setLoading(false)
            })
        }
    }

    return (
        <div>
            <h3 style={{marginBottom: 16}}>Next of Kin - Emergency Contact</h3>
            <div>
                <FormControl
                    error={Boolean(errors.emergencyContactName)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Full Name</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter full name"
                                fullWidth
                                variant="filled"
                                error={errors.emergencyContactName ? true : false}
                                helperText={errors.emergencyContactName?.message}/>
                        }
                        name="emergencyContactName"
                        control={control}
                        defaultValue=""
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.emergencyContactRelationship)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Relationship</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter relationship"
                                fullWidth
                                variant="filled"
                                error={errors.emergencyContactRelationship ? true : false}
                                helperText={errors.emergencyContactRelationship?.message}/>
                        }
                        name="emergencyContactRelationship"
                        control={control}
                        defaultValue=""
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.emergencyContactPhone)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Phone</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter phone"
                                fullWidth
                                variant="filled"
                                error={errors.emergencyContactPhone ? true : false}
                                helperText={errors.emergencyContactPhone?.message}/>
                        }
                        name="emergencyContactPhone"
                        control={control}
                        defaultValue=""
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.emergencyContactMobile)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Mobile</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter mobile"
                                fullWidth
                                variant="filled"
                                error={errors.emergencyContactMobile ? true : false}
                                helperText={errors.emergencyContactMobile?.message}/>
                        }
                        name="emergencyContactMobile"
                        control={control}
                        defaultValue=""
                    />
                </FormControl>

            </div>

            <div>
                <Button style={{marginTop: 16}} variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={loading}>
                    Next
                </Button>
            </div>
        </div>
    )
}
