import React, { useState, useEffect } from 'react'
import {
    Stepper, Step, StepLabel, Button
} from "@material-ui/core"
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Ird from "./Ird";
import Payroll from "./Payroll";
import EmploymentContract from './EmploymentContract';
import { Candidate } from '../../CandidateTypes';
import { useHistory } from 'react-router-dom';
import NextOfKin from './NextOfKin';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    header: {
        display: "flex",
        marginBottom: 16
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
  }),
);
interface Props {
  candidate: Candidate | undefined
}

export default function JobPlacement({ candidate }: Props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const history = useHistory()

    useEffect(() => {
      if(candidate) {
        if(candidate?.jobPlacementStep) {
          setActiveStep(candidate?.jobPlacementStep)
        }
      }
    }, [candidate])
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    function getStepContent(step: number) {
      switch (step) {
        case 0:
            return <NextOfKin activeStep={activeStep} candidate={candidate} incrementActiveStep={handleNext} />
        case 1:
            return <EmploymentContract activeStep={activeStep} candidate={candidate} incrementActiveStep={handleNext}/>
        case 2:
          return <Ird incrementActiveStep={handleNext} candidate={candidate} activeStep={activeStep} />;
        case 3:
          return <Payroll incrementActiveStep={handleNext} candidate={candidate} activeStep={activeStep} />;
        case 4:
          return (
            <div>
              <h3>Complete</h3>
              <p>Congratulations you have completed the job placement process.</p>
              <Button style={{marginTop: 8}} variant="contained" color="primary" onClick={() => history.push("/")}>
                Return home
              </Button>
            </div>
          )
        default:
          return '';
      }
    }
  
    return (
        <div className="card">
             <div className={classes.header}>
                <h2>Job Placement</h2>
            </div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {[...Array(5)].map((_, i: number) => (
            <Step key={i}>
                <StepLabel></StepLabel>
            </Step>
            ))}
            </Stepper>
            <div>
                <div>
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                    {/* <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                      <Button variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}