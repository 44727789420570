import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import "../../App.css";
import Registration from "./Registration";
import {
  CircularProgress, Button, Card, FormControl,
  FormLabel, TextField
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import {
  useHistory
} from "react-router-dom";
import { MyAuth } from "../../hooks/useAuth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookSquare, faApple } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginPage: {
      [theme.breakpoints.up('sm')]: {
        display: "flex",
        justifyContent: "center",
        marginTop: 32,
        height: "100vh"
      },
      [theme.breakpoints.down('xs')]: {
        padding: "24px 0px"
      }
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
    googleButton: {
        backgroundColor: "#dd4b39",
        "&:hover": {
            backgroundColor: "#ff7d64"
        },
        marginBottom: 16, 
        justifyContent: "flex-start",
        [theme.breakpoints.up('sm')]: {
          width: "300px"
        },
        [theme.breakpoints.down('xs')]: {
          width: "100%"
        }
    },
    facebookButton: {
        backgroundColor: "#3b5998",
        "&:hover": {
            backgroundColor: "#6d85c9"
        },
        marginBottom: 16, 
        justifyContent: "flex-start",
        [theme.breakpoints.up('sm')]: {
          width: "300px"
        },
        [theme.breakpoints.down('xs')]: {
          width: "100%"
        }
    },
    appleButton: {
        backgroundColor: "#000000",
        "&:hover": {
            backgroundColor: "#424242"
        },
        justifyContent: "flex-start",
        [theme.breakpoints.up('sm')]: {
          width: "300px"
        },
        [theme.breakpoints.down('xs')]: {
          width: "100%"
        }
    },
    error: {
      '&.MuiFormHelperText-contained': {
        marginLeft: 0
      }
    },
    submit: {
      marginTop: theme.spacing(3),
      width: 100
    },
    socialButtonDiv: {
      [theme.breakpoints.down('xs')]: {
        width: "100%"
      }
    }
  }),
);

interface LoginProps {
    auth: MyAuth;
}

interface LoginForm {
    email: string;
    password: string;
}
  
export default function Login({ auth }: LoginProps) {

    const classes = useStyles()

    const { handleSubmit, control, errors } = useForm<LoginForm>({
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const [error, setError] = useState<string>()

    let history = useHistory();
    
    const handleLogin = async (data: LoginForm) => {
      // console.log(data)
      if(data) {
          try {
            await auth.signIn(data.email, data.password)
          } catch(e) {
            console.log(e)
            if(e.message === "UserNotConfirmedException") {
              history.push({ pathname: "/verify", state: { userEmail: data.email } })
            } else {
              setError(e.message)
            }
          }
      }
    }

    const getFromRoute = (): string => {
      let route = localStorage.getItem("lowie-route-from")
      if(route) {
        // console.log(route)
        localStorage.removeItem("lowie-route-from")
        if(route === "/") return "/portal"
        return route

      } else {
        return "/portal"
      }
    }

    useEffect(() => {
      if(auth.isCandidateSignedIn) {
        history.replace(getFromRoute())
      }
    }, [auth.isCandidateSignedIn])

    useEffect(() => {
      if(window.location.search.includes("error")) {
        setError("A user with the same email address exists")
        window.history.replaceState(null, "", window.location.pathname);
      }
    }, [])

    return (
      <>
        {auth.loading ?
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 16,
                }}
              >
                <CircularProgress size={150} />
              </div>
              <h1>Authenticating...</h1>
            </div>
          </div>
        
        :
          <>
            {auth.isSignedIn ? (
              <>
                {!auth.isCandidateSignedIn &&
                  <Registration auth={auth} />
                }
              </>
            ) : (
              <div className={classes.loginPage}>

                <div>
                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 32
                  }}>
                      <img
                          src="images/lowie-logo-1.png"
                          alt="Lowie Recruitment"
                          width={300}
                      />
                  </div>


                  <Card style={{marginBottom: 32}}>
                      <div style={{padding: 16}}>

                          <div style={{marginBottom: 32, display: "flex", justifyContent: "center"}}>
                              <h3>Sign in into your account</h3>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", marginBottom: 32}}>
                              <div className={classes.socialButtonDiv}>
                                  <div>
                                      <Button 
                                          className={classes.googleButton}
                                          variant="contained" 
                                          color="primary"
                                          onClick={() => auth.socialSignIn(CognitoHostedUIIdentityProvider.Google, getFromRoute())}
                                          startIcon={
                                              <FontAwesomeIcon icon={faGoogle} style={{marginRight: 16}} />
                                          }>
                                          Continue with Google
                                      </Button>
                                  </div>
                                  <div>
                                      <Button
                                          className={classes.facebookButton}
                                          variant="contained" 
                                          color="primary" 
                                          onClick={() => auth.socialSignIn(CognitoHostedUIIdentityProvider.Facebook, getFromRoute())}
                                          startIcon={
                                              <FontAwesomeIcon icon={faFacebookSquare} style={{marginRight: 16}} />
                                          }>
                                          Continue with Facebook
                                      </Button>
                                  </div>
                                  <div>
                                      <Button 
                                          className={classes.appleButton}
                                          variant="contained" 
                                          color="primary" 
                                          onClick={() => auth.socialSignIn(CognitoHostedUIIdentityProvider.Apple, getFromRoute())}
                                          startIcon={
                                              <FontAwesomeIcon icon={faApple} style={{marginRight: 16}} />
                                          }>
                                          Continue with Apple
                                      </Button>
                                  </div>
                              </div>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", marginBottom: 32, color: "grey"}}>
                              <div style={{flexGrow: 1, marginRight: 16}}>
                                  <hr />
                              </div>
                              <h6>
                                  OR
                              </h6>
                              <div style={{flexGrow: 1, marginLeft: 16}}>
                                  <hr />
                              </div>
                          </div>

                          <div style={{marginBottom: 32}}>

                              {error &&
                                <div style={{color: 'red', marginBottom: 16}}>
                                  <h6>{error}</h6>
                                </div>
                              }
                              <FormControl 
                                  className={classes.input}
                                  error={Boolean(errors.email)}>

                                  <FormLabel className={classes.label}>Email:</FormLabel>

                                  <Controller
                                      as={
                                      <TextField
                                          placeholder=""
                                          variant="outlined"
                                          fullWidth
                                          size="small"
                                          error={errors.email ? true : false}
                                          helperText={errors.email?.message}/>
                                      }
                                      name="email"
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                      required: "This is required"
                                      }}
                                  />
                              </FormControl>

                              <FormControl 
                                  className={classes.input}
                                  error={Boolean(errors.password)}>

                                  <FormLabel className={classes.label}>Password:</FormLabel>

                                  <Controller
                                      as={
                                      <TextField
                                          placeholder=""
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                          error={errors.password ? true : false}
                                          helperText={errors.password?.message}
                                          type="password"/>
                                      }
                                      name="password"
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                      required: "This is required"
                                      }}
                                  />
                              </FormControl>

                              <a href="#/forgot-password">
                                  Forgot your password?
                              </a>
                          </div>

                          <div>
                              <Button variant="contained" color="primary" style={{width: "100%", padding: 16}} onClick={handleSubmit(handleLogin)}>
                                  <h5>
                                      Sign In
                                  </h5>
                              </Button>

                          </div>
                      </div>
                  </Card>

                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                  }}>
                      <h4>Don't have an account?</h4>
                      <Button variant="contained" color="primary" style={{marginLeft: 16}} onClick={() => history.push("/register")}>
                          Register
                      </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      </>
    );
  }