import React, { useState, useEffect } from 'react'
import {
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import {
    Select, MenuItem, TextField, FormControl, FormLabel, FormHelperText, Button,
    FormControlLabel, Checkbox, Typography
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form"
import { API } from "aws-amplify";
import { Candidate, PersonalInfoPart2 } from '../../CandidateTypes';
import PlacesAutocomplete from 'react-places-autocomplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        },
        addressOptions: {
            zIndex: 2,
            position: "absolute",
            width: "-webkit-fill-available"
        }
    })
);
interface Props {
    candidate?: Candidate
    activeStep: number;
    incrementActiveStep: () => void
}

export default function RegistrationProfilePart2({ candidate, activeStep, incrementActiveStep }: Props) {
    const classes = useStyles()
    const { handleSubmit, control, errors, setValue, setError, clearErrors, getValues, watch, reset } = useForm<PersonalInfoPart2>({
        shouldUnregister: false
    });

    const [isLoading, setLoading] = useState<boolean>(false);
    const [addressInput, setAddressInput] = useState<string>('')
    const [addressValue, setAddressValue] = useState<string>()

    let wAllTheAbove = watch('availability.alltheabove')
    useEffect(() => {
        console.log(wAllTheAbove)
        setValue('availability.temporary', wAllTheAbove)
        setValue('availability.permanent', wAllTheAbove)
        setValue('availability.contract', wAllTheAbove)
    }, [wAllTheAbove]);

    useEffect(() => {
        let jsonForm = localStorage.getItem('personalInfoPart2Form')
        if (jsonForm) {
            let form: PersonalInfoPart2 = JSON.parse(jsonForm)
            reset(form)
            setAddressInput(form.address)
            setAddressValue(form.address)
        } else {
            if (candidate) {
                setValue("phone", candidate.phone)
                setValue("jobType", candidate.jobType)
            }
        }
    }, [candidate, setValue]);

    const handleNext = (data: PersonalInfoPart2) => {

        if (!addressValue) {
            setError("address", { message: "Invalid address" })
        } else {
            setLoading(true);

            data.address = addressValue

            localStorage.setItem('personalInfoPart2Form', JSON.stringify(data))

            const params = {
                body: {
                    data: data,
                    step: activeStep
                }
            };
            API.post("", `/candidates/${candidate?.username}/registration`, params)
                .then(() => {
                    setLoading(false);
                    incrementActiveStep();
                })
                .catch((error: any) => {
                    console.log("ERROR updating registration for candidate", error);
                    setLoading(false);
                })
        }
    };

    return (
        <div>
            <h3 style={{ marginBottom: 16 }}>Personal Information - Continued</h3>

            <FormControl
                error={Boolean(errors.address)}
                className={classes.input}>

                <FormLabel className={classes.label}>Address</FormLabel>

                <PlacesAutocomplete
                    value={addressInput}
                    onChange={(address: string) => {
                        setAddressInput(address)
                        clearErrors("address")
                        setAddressValue(undefined)
                    }}
                    onSelect={(_, placeID: string) => {
                        var request = {
                            placeId: placeID
                        };

                        var placeRequest: Promise<google.maps.places.PlaceResult> = new Promise((resolve, reject) => {
                            new google.maps.places.PlacesService(document.createElement('div')).getDetails(request, (place, status) => {
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    resolve(place);
                                } else { reject() };
                            });
                        })
                        placeRequest.then((result: google.maps.places.PlaceResult) => {
                            if (result && result?.formatted_address && result?.address_components) {
                                setAddressInput(result.formatted_address)
                                setAddressValue(result.formatted_address)

                                result.address_components.forEach((c) => {
                                    if (c.types.includes("street_number")) {
                                        setValue("streetNumber", c.long_name)

                                    } else if (c.types.includes("route")) {
                                        setValue("street", c.long_name)

                                    } else if (c.types.includes("sublocality_level_1")) {
                                        setValue("suburb", c.long_name)

                                    } else if (c.types.includes("locality")) {
                                        setValue("region", c.long_name)

                                    } else if (c.types.includes("country")) {
                                        setValue("country", c.long_name)

                                    } else if (c.types.includes("postal_code")) {
                                        setValue("postcode", c.long_name)

                                    }
                                })
                            }
                        })
                            .catch((error: any) => {
                                console.log("Error: ", error)
                            })
                    }}
                    searchOptions={{
                        componentRestrictions: { country: ['au', 'nz'] },
                        types: ['address']
                    }}
                    onError={(_, clearSuggestions) => {
                        clearSuggestions()
                    }}>

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <TextField
                                fullWidth
                                variant="filled"

                                {...getInputProps({
                                    placeholder: 'Enter address',
                                })}
                            />
                            <div className={classes.addressOptions}>
                                {loading &&
                                    <div style={{ backgroundColor: '#ffffff', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa" }}>
                                        <Typography variant="body1">
                                            Loading ...
                                        </Typography>
                                    </div>
                                }

                                {suggestions.map((suggestion: any, i: number) => {
                                    let style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" };

                                    if ((i === suggestions.length - 1) && !suggestion.active) {
                                        style = { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "2px solid #fafafa" }
                                    }

                                    return (
                                        <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                            <Typography variant="body1">{suggestion.description}</Typography>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <FormHelperText>
                    {errors.address && "Invalid address"}
                </FormHelperText>
            </FormControl>

            <FormControl
                className={classes.input}>

                <FormLabel className={classes.label}>Mobile Number</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter mobile"
                            fullWidth
                            variant="filled"
                            disabled />
                    }
                    name="phone"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            {/* <FormControl
                error={Boolean(errors.jobType)} 
                className={classes.input}>
        
                <FormLabel className={classes.label}>Select Job Type/s</FormLabel>
        
                {[{"label": "Permanent", "name": "permanent"}, {"label": "Temporary", "name": "temporary"}].map(item => (
                    <Controller
                        key={item.label}
                        render={(props) => (
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        onChange={e => {
                                            props.onChange(e.target.checked)
                                        }} 
                                        checked={props.value} />
                                } 
                                label={item.label} />
                        )}
                        name={`jobType.${item.name}`}
                        control={control}
                        defaultValue={false}
                        rules={{
                            validate: (value: boolean) => {
                                if(value) {
                                    clearErrors("jobType")
                                    return true
                                } else {
                                    let v = watch("jobType")
                                    if(v) {
                                        if(v.permanent === false && v.temporary === false) {
                                            setError("jobType", { message: "At least 1 required"})
                                            return false
                                        }
                                    } else {
                                        setError("jobType", { message: "At least 1 required"})
                                        return false
                                    }
                                    return true
                                }
                                
                            }
                        }}
                    />
                ))}

                <FormHelperText>
                    {errors.jobType && "At least 1 required"}
                </FormHelperText>
            </FormControl> */}

            <FormControl
                error={Boolean(errors.availability)}
                className={classes.input}>

                <FormLabel className={classes.label}>What is your availability?</FormLabel>

                {[{ "label": "Temporary", "name": "temporary" }, { "label": "Permanent", "name": "permanent" }, { "label": "Contract", "name": "contract" }, { "label": "All of the above", "name": "alltheabove" }].map(item => (
                    <Controller
                        key={item.label}
                        render={(props) => (
                            <FormControlLabel
                                control={
                                    <Checkbox onChange={e => {
                                        props.onChange(e.target.checked)
                                    }}
                                        checked={props.value} />
                                }
                                label={item.label} />
                        )}
                        name={`availability.${item.name}`}
                        control={control}
                        defaultValue={false}
                        rules={{
                            validate: (value: boolean) => {
                                if (value) {
                                    clearErrors("availability")
                                    return true
                                } else {
                                    let v = getValues("availability")
                                    if (v) {
                                        if (v.temporary === false && v.permanent === false && v.contract === false && v.alltheabove === false) {
                                            setError("availability", { message: "At least 1 required" })
                                            return false
                                        }
                                    } else {
                                        setError("availability", { message: "At least 1 required" })
                                        return false
                                    }
                                    return true
                                }
                            }
                        }}
                    />
                ))}

                <FormHelperText>
                    {errors.availability && "At least 1 required"}
                </FormHelperText>

            </FormControl>

            <FormControl
                error={Boolean(errors.transport)}
                className={classes.input}>

                <FormLabel className={classes.label}>Do you have reliable transport?</FormLabel>

                <Controller
                    as={
                        <Select variant="filled"
                            defaultValue=""
                            displayEmpty={true}>
                            <MenuItem value="">Select an option</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    }
                    name="transport"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.transport && errors.transport.message}
                </FormHelperText>
            </FormControl>

            <FormControl
                error={Boolean(errors.criminalConvictions)}
                className={classes.input}>

                <FormLabel className={classes.label}>Do you have any past / pending criminal convictions?</FormLabel>

                <Controller
                    as={
                        <Select variant="filled"
                            defaultValue=""
                            displayEmpty={true}>
                            <MenuItem value="">Select an option</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    }
                    name="criminalConvictions"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.criminalConvictions && errors.criminalConvictions.message}
                </FormHelperText>
            </FormControl>

            {watch("criminalConvictions") === "Yes" &&
                <FormControl
                    error={Boolean(errors.criminalConvictionDetail)}
                    className={classes.input}>

                    <FormLabel className={classes.label}>Please state what the conviction(s) is/are:</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter detail"
                                fullWidth
                                variant="filled"
                                error={errors.criminalConvictionDetail ? true : false}
                                helperText={errors.criminalConvictionDetail?.message} />
                        }
                        name="criminalConvictionDetail"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            }

            <div>
                <Button variant="contained" color="primary" onClick={handleSubmit(handleNext)} disabled={isLoading}>
                    Next
                </Button>
            </div>
        </div>
    )
}
