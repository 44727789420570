import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useParams, useLocation, Redirect, useHistory } from 'react-router-dom';
import {
    Typography, Paper, IconButton, Button, CircularProgress, Hidden
} from "@material-ui/core"
import { Job, calcSalary, calculateExpiryDate, ApplyDialog } from './Jobs';
import JobCarousel from './JobCarousel';
import { 
    ArrowBackIos, ArrowForwardIos, FormatListBulleted, LocationOn, AccountBalanceWallet,
    DateRange, NoteAdd, SmsOutlined
} from '@material-ui/icons';
import { Candidate } from "../../CandidateTypes";
import { API } from "aws-amplify";
import { 
    EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, 
    FacebookShareButton, WhatsappIcon, WhatsappShareButton
} from 'react-share';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        jobHeading: {
            marginTop: 8,
            display: 'flex', 
            alignItems: 'center',
            flexGrow: 1
        },
        jobInfo: {
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap',
        },
        classificationInfo: {
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap',
            paddingBottom: 8,
            paddingLeft: 32,
            paddingRight: 32

        },
        jobInfoIcon: {
            marginRight: 4
        },
        headingDiv: {
            display: "flex",
            padding: 32
        },
        subHeadingDiv: {
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 16,
            paddingLeft: 32,
            paddingRight: 32
        },
        mobileHeadingDiv: {
            padding: 8
        },
        mobileJobInfo: {
            display: 'flex', 
            alignItems: 'center',
            marginBottom: 8
        },
        jobDescription: {
            [theme.breakpoints.up('sm')]: {
                padding: 64
            },
            [theme.breakpoints.down('xs')]: {
                padding: 32
            }
        },
        shareButton: {
            marginRight: 8
        },
        mobileFBMessenger: {
            marginTop: 6,
            '&.MuiIconButton-label': {
                width: 36
            }
        }
    })
);

interface ParamTypes {
    jobRef: string;
}

interface Props {
    jobs: Array<Job>;
    currentJobIdx: number;
    candidate: Candidate | undefined
}

export default function JobDetails() {

    const classes = useStyles()
    const { jobRef } = useParams<ParamTypes>();
    const location = useLocation<Props>();
    const history = useHistory();

    const [jobs, setJobs] = useState<Array<Job>>([])
    const [currentJobIdx, setCurrentJobIdx] = useState<number>(-1)
    const [similarJobs, setSimilarJobs] = useState<Array<Job>>([])

    const [candidate, setUser] = useState<Candidate>()

    const [applyLoading, setApplyLoading] = useState<boolean>(false)
    const [applySuccessful, setApplySuccessful] = useState<boolean>(false)
    const [openApplyDialog, setOpenApplyDialog] = useState<boolean>(false)

    useEffect(() => {
        if(location.state) {
            window.scrollTo(0, 0)
            setJobs(location.state.jobs)
            setCurrentJobIdx(location.state.currentJobIdx)
            setUser(location.state.candidate)
        }
    }, [location.state, jobRef])


    useEffect(() => {
        if(jobs.length > 0 && (currentJobIdx >= 0 && currentJobIdx < jobs.length)) {
            let currJob = jobs[currentJobIdx]
            let similarJobsList: Array<Job> = []
            jobs.forEach((j: Job) => {
                if(j.Reference !== currJob.Reference && j.Classification === currJob.Classification) {
                    similarJobsList.push(j)
                }
            })
            setSimilarJobs(similarJobsList)
        }
    }, [jobs, currentJobIdx])

    const convertTimestampToDate = (timestamp: string) : string => {
        let timeInSecs = parseInt(timestamp)
        let date = new Date(0);
        date.setUTCSeconds(timeInSecs);
        return date.toDateString();
    }

    const applyCandidateForJob = (jobRef: string) => {
        if(candidate) {
            setApplyLoading(true)
            let params = {
                body: {
                    username: candidate.username,
                    jobRef: jobRef,
                    data: candidate
                }
            }
            API.post("", `/candidates/apply`, params)
            .then(() => {
                setApplySuccessful(true)
                setApplyLoading(false)
                setOpenApplyDialog(true)
            })
            .catch((error: any) => {
                console.log("Error: applying candidate for job", error)
                setApplySuccessful(false)
                setApplyLoading(false)
                setOpenApplyDialog(true)

            })
        } else {
            setApplySuccessful(false)
            setOpenApplyDialog(true)
        }
    }

    const openSMSMobile = () => {
        window.open(`sms:&body=Check out this job! https://lowie.co.nz/job-details/?id=${jobRef}`, '_self')
        return false
    }

    const openFBMessengerShare = () => {
        window.open('fb-messenger://share?link=' +
        encodeURIComponent(`https://lowie.co.nz/job-details/?id=${jobRef}`) +
        '&app_id=' +
        encodeURIComponent(1010681839421699))
    }

    const checkMobile = (): boolean => {
        // console.log(navigator.userAgent)
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true
        }
        return false
    }

    return location.state ? (
        <div>
            <div>
                <Paper elevation={3}>
                    {(currentJobIdx >= 0 && currentJobIdx < jobs.length) && 
                        <div>
                            <div style={{backgroundImage: "linear-gradient(#95c54b, #259a49)", color: "white"}}>
                                <Hidden xsDown>
                                    <div className={classes.headingDiv}>
                                        <div className={classes.jobHeading}>
                                            <IconButton 
                                                style={{color: "white"}}
                                                onClick={() => history.goBack()}>
                                                <ArrowBackIos  />
                                            </IconButton>
                                            <Typography variant="h4" style={{textTransform: "uppercase"}}>
                                                {jobs[currentJobIdx].Title}
                                            </Typography>
                                        </div>
                                        <div style={{minWidth: 100}}>
                                            <IconButton 
                                                style={{color: "white"}}
                                                onClick={() => history.push({pathname: `/portal/jobs/${jobs[currentJobIdx-1].Reference}`, state: {jobs: jobs, currentJobIdx: currentJobIdx-1}})}
                                                disabled={currentJobIdx === 0}>
                                                <ArrowBackIos />
                                            </IconButton>
                                            <IconButton 
                                                style={{color: "white"}} 
                                                onClick={() => history.push({pathname: `/portal/jobs/${jobs[currentJobIdx+1].Reference}`, state: {jobs: jobs, currentJobIdx: currentJobIdx+1}})}
                                                disabled={currentJobIdx === jobs.length-1}>
                                                <ArrowForwardIos />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className={classes.classificationInfo}> 
                                        <FormatListBulleted className={classes.jobInfoIcon}/> 
                                        <Typography variant="subtitle1">
                                            {jobs[currentJobIdx].Classification} / {jobs[currentJobIdx].Position}
                                        </Typography>
                                    </div> 
                                    <div className={classes.subHeadingDiv}>
                                        <div className={classes.jobInfo}> 
                                            <LocationOn className={classes.jobInfoIcon}/> 
                                            <Typography variant="subtitle1">
                                                {jobs[currentJobIdx].Location}
                                            </Typography>
                                        </div> 
                                        <div className={classes.jobInfo}> 
                                            <AccountBalanceWallet className={classes.jobInfoIcon} /> 
                                            <Typography variant="subtitle1">
                                                {calcSalary(jobs[currentJobIdx])}
                                            </Typography>
                                        </div> 
                                        <div className={classes.jobInfo}> 
                                            <NoteAdd className={classes.jobInfoIcon} /> 
                                            <Typography variant="subtitle1">
                                                {convertTimestampToDate(jobs[currentJobIdx].timestamp)}
                                            </Typography>
                                        </div> 
                                        <div className={classes.jobInfo}> 
                                            <DateRange className={classes.jobInfoIcon} /> 
                                            <Typography variant="subtitle1">
                                                {calculateExpiryDate(jobs[currentJobIdx].timestamp)}
                                            </Typography>
                                        </div> 
                                    </div>
                                </Hidden>
                                <Hidden smUp>
                                    <div className={classes.mobileHeadingDiv}>
                                        <div className={classes.jobHeading}>
                                            <IconButton 
                                                style={{color: "white"}}
                                                onClick={() => history.goBack()}>
                                                <ArrowBackIos  />
                                            </IconButton>
                                            <Typography variant="h5">
                                                {jobs[currentJobIdx].Title}
                                            </Typography>
                                        </div>
                                        <div style={{marginTop: 16, marginLeft: 20, marginRight: 8}}>
                                            <div className={classes.mobileJobInfo}> 
                                                <FormatListBulleted className={classes.jobInfoIcon}/> 
                                                <Typography variant="subtitle1">
                                                    {jobs[currentJobIdx].Classification} / {jobs[currentJobIdx].Position}
                                                </Typography>
                                            </div> 
                                            <div className={classes.mobileJobInfo}> 
                                                <LocationOn className={classes.jobInfoIcon}/> 
                                                <Typography variant="subtitle1">
                                                    {jobs[currentJobIdx].Location}
                                                </Typography>
                                            </div> 
                                            <div className={classes.mobileJobInfo}> 
                                                <AccountBalanceWallet className={classes.jobInfoIcon} /> 
                                                <Typography variant="subtitle1">
                                                    {calcSalary(jobs[currentJobIdx])}
                                                </Typography>
                                            </div> 
                                            <div className={classes.mobileJobInfo}> 
                                                <NoteAdd className={classes.jobInfoIcon} /> 
                                                <Typography variant="subtitle1">
                                                    {convertTimestampToDate(jobs[currentJobIdx].timestamp)}
                                                </Typography>
                                            </div> 
                                            <div className={classes.mobileJobInfo}> 
                                                <DateRange className={classes.jobInfoIcon} /> 
                                                <Typography variant="subtitle1">
                                                    {calculateExpiryDate(jobs[currentJobIdx].timestamp)}
                                                </Typography>
                                            </div> 
                                        </div>
                                        <div style={{display: "flex"}}>
                                            <div style={{flexGrow: 1}}></div>
                                            <div>
                                                <IconButton 
                                                    style={{color: "white"}}
                                                    onClick={() => history.push({pathname: `/portal/jobs/${jobs[currentJobIdx-1].Reference}`, state: {jobs: jobs, currentJobIdx: currentJobIdx-1}})}
                                                    disabled={currentJobIdx === 0}>
                                                    <ArrowBackIos />
                                                </IconButton>
                                                <IconButton 
                                                    style={{color: "white"}} 
                                                    onClick={() => history.push({pathname: `/portal/jobs/${jobs[currentJobIdx+1].Reference}`, state: {jobs: jobs, currentJobIdx: currentJobIdx+1}})}
                                                    disabled={currentJobIdx === jobs.length-1}>
                                                    <ArrowForwardIos />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </Hidden>
                            </div>
                            <div>
                                <div style={{display: "flex", paddingRight: 32, paddingTop: 32}}>
                                    <div style={{flexGrow: 1}}></div>
                                    <div>
                                        <Button variant="contained" color="primary" style={{width: 130}}
                                            onClick={() => {
                                                applyCandidateForJob(jobs[currentJobIdx].Reference)
                                            }}>
                                            {applyLoading ?
                                                <CircularProgress size={25} style={{color: "#ffffff"}} />
                                            :
                                                <span>Apply Now</span>
                                            }
                                        </Button>
                                    </div>
                                </div>

                                <div style={{display: "flex", justifyContent: "center", marginTop: 16}}>
                                    <Typography style={{fontWeight: "bold"}} variant="subtitle1" gutterBottom>
                                        Share this role
                                    </Typography>
                                </div>

                                <Hidden smUp>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <FacebookShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`} quote={`Check out this new position for ${jobs[currentJobIdx].Title}!`}>
                                            <FacebookIcon size={36} round />
                                        </FacebookShareButton>
                                        {checkMobile() ?
                                            <div style={{width: 34, height: 39, padding: 1, marginRight: 8}}>
                                                <IconButton style={{width: 36, height: 36}} onClick={() => openFBMessengerShare()}>
                                                    <div className={classes.mobileFBMessenger}>
                                                        <FacebookMessengerIcon size={36} round />
                                                    </div>
                                                </IconButton>
                                            </div>
                                        :
                                            <FacebookMessengerShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`} appId="1010681839421699">
                                                <FacebookMessengerIcon size={36} round />
                                            </FacebookMessengerShareButton>
                                        }
                                        
                                        <WhatsappShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`}
                                            title={`Check out this new position for ${jobs[currentJobIdx].Title}!`}
                                            separator={`\n\n`}>
                                            <WhatsappIcon size={36} round />
                                        </WhatsappShareButton>
                                        <EmailShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`}
                                            subject={`${jobs[currentJobIdx].Title} Position`}
                                            body={`Check out this new position for ${jobs[currentJobIdx].Title}!`}
                                            separator={`\n\n`}>
                                            <EmailIcon size={36} round />
                                        </EmailShareButton>
                                        <div style={{width: 32, height: 37, padding: 2}}>
                                            <IconButton style={{backgroundColor: "#FFBE00", width: 36, height: 36}} onClick={() => openSMSMobile()}>
                                                <SmsOutlined style={{color: "white", width: 19, marginTop: 3}}/>
                                            </IconButton>
                                        </div>
                                    </div>
                                </Hidden>
                                    
                                <Hidden xsDown>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <FacebookShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`} quote={`Check out this new position for ${jobs[currentJobIdx].Title}!`}>
                                            <FacebookIcon size={46} round />
                                        </FacebookShareButton>
                                        <FacebookMessengerShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`} appId="1010681839421699">
                                            <FacebookMessengerIcon size={46} round />
                                        </FacebookMessengerShareButton>
                                        <WhatsappShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`}
                                            title={`Check out this new position for ${jobs[currentJobIdx].Title}!`}
                                            separator={`\n\n`}>
                                            <WhatsappIcon size={46} round />
                                        </WhatsappShareButton>
                                        <EmailShareButton className={classes.shareButton} url={`https://lowie.co.nz/job-details/?id=${jobRef}`}
                                            subject={`${jobs[currentJobIdx].Title} Position`}
                                            body={`Check out this new position for ${jobs[currentJobIdx].Title}!`}
                                            separator={`\n\n`}>
                                            <EmailIcon size={46} round />
                                        </EmailShareButton>
                                        <div style={{width: 44, height: 47, padding: 2}}>
                                            <IconButton style={{backgroundColor: "#FFBE00", width: 46, height: 46}} onClick={() => openSMSMobile()}>
                                                <SmsOutlined style={{color: "white", width: 24, marginTop: 3}}/>
                                            </IconButton>
                                        </div>

                                    </div>
                                </Hidden>

                                <div className={classes.jobDescription} dangerouslySetInnerHTML={{__html: jobs[currentJobIdx].AdDetail}} />

                                <Hidden smUp>
                                    <div style={{display: "flex", paddingRight: 32, paddingBottom: 32}}>
                                        <div style={{flexGrow: 1}}></div>
                                        <div>
                                            <Button variant="contained" color="primary" style={{width: 130}}
                                                onClick={() => {
                                                    applyCandidateForJob(jobs[currentJobIdx].Reference)
                                                }}>
                                                {applyLoading ?
                                                    <CircularProgress size={25} style={{color: "#ffffff"}} />
                                                :
                                                    <span>Apply Now</span>
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Hidden>

                                {similarJobs.length !== 0 &&
                                    <div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <Typography variant="h5" style={{color: "#259a49"}}>
                                                Similar Jobs Available
                                            </Typography>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center", paddingBottom: 32}}>
                                            <JobCarousel similarJobs={similarJobs} jobs={jobs} candidate={candidate} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Paper>
            </div>

            <ApplyDialog
                open={openApplyDialog}
                handleDialog={setOpenApplyDialog}
                success={applySuccessful}
                job={jobs[currentJobIdx]} />
        </div>
    ) : (
        <Redirect to="/portal/jobs" />
    )
}
