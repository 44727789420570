import React, { useEffect, useState } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Card, CardHeader, CardContent, Grid, Select, MenuItem, TextField,
    FormControl, FormLabel, FormHelperText, Button, CardActions, CircularProgress,
    Checkbox, FormControlLabel, Typography, Paper, List, ListItem, ListItemIcon,
    ListItemText
} from "@material-ui/core"; 
import { useForm, Controller } from "react-hook-form"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { PersonalInfo, Candidate } from '../../CandidateTypes';
import { Edit, Clear, Save, InsertDriveFile } from "@material-ui/icons";
import { API, Storage } from "aws-amplify";
import isMobilePhone from "validator/lib/isMobilePhone";
import PlacesAutocomplete from 'react-places-autocomplete';
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        divider: {
            marginBottom: 16
        },
        categoryCard: {
            display: "flex",
            padding: 16,
            height: 72
        },
        input: {
            width: "100%"
        },
        label: {
            marginBottom: 6
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 97,
            paddingBottom: 97,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        },
        dropzoneFile: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 39,
            paddingBottom: 39,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        },
        disabled: {
            opacity: 0.4
        },
        editButton: {
            width: 100,
            marginTop: 8,
            marginRight: 8
        },
        actionButton: {
            marginRight: 8,
            marginBottom: 8,
            width: 100
        },
        addressOptions: {
            zIndex: 2,
            position: "absolute",
            width: "-webkit-fill-available"
        }
    })
);



interface Props {
    data?: PersonalInfo
    detail?: Candidate
    refreshCandidateData: () => void
}

export default function PersonalInfoCard({ data, detail, refreshCandidateData } : Props) {
    const classes = useStyles()
    const { handleSubmit, control, errors, setValue, clearErrors, setError, getValues, watch } = useForm<PersonalInfo>({
        shouldUnregister: false
    });

    const [addressInput, setAddressInput] = useState<string>('')
    const [addressValue, setAddressValue] = useState<string>()

    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setAPIError] = useState<string>()

    const [uploadedCvFile, setCvFile] = useState<Array<File>>([])
    const [uploadedProfilePhoto, setProfilePhoto] = useState<Array<File>>([])

    const onSave = (data: PersonalInfo) => {
        if(detail) {
            if(isMobilePhone(data.phone, ["en-AU", "en-NZ"])) {
                if(!addressValue) {
                    setError("address", {message: "Invalid address"})
                } else {
                    data.address = addressValue
                    setLoading(true)
                    const params = {
                        body: {
                            data: data,
                            section: "profile",
                            cvFile: getValues("cvFile")
                        }
                    };
                    API.post("", `/candidates/${detail?.username}/profile/update`,params)
                    .then(() => {
                        setReadOnly(true);
                        setLoading(false);
                        refreshCandidateData()
                    })
                    .catch((error: any) => {
                        console.log("ERROR updating candidate profile", error);
                        setLoading(false)
                        setAPIError("Error: unable to update info")
                    })   
                }
            } else {
                setError("phone", {message: "Invalid number"})
                return
            }   
        }
    }

    useEffect(() => {
        if(data) {
            setValue("title", data.title)
            setValue("firstName", data.firstName)
            setValue("surname", data.surname)
            setValue("dob", data.dob)
            setValue("address", data.address)
            if(data.address) {
                setAddressInput(data.address)
                setAddressValue(data.address)
            }
            setValue("phone", data.phone)
            setValue("email", data.email)
            setValue("gender", data.gender)
            setValue("jobType", data.jobType)           
            setValue("availability", data.availability)  
            setValue("streetNumber", data.streetNumber)
            setValue("street", data.street)
            setValue("suburb", data.suburb)
            setValue("region", data.region)
            setValue("country", data.country)
            setValue("postcode", data.postcode)
            setValue("profilePhoto", data.profilePhoto)

        } else if(detail) {
            setValue("title", detail.title)
            setValue("firstName", detail.firstName)
            setValue("surname", detail.surname)
            setValue("phone", detail.phone)
            setValue("email", detail.email)
            setValue("cvFile", detail.cvFile)
            
        }
    }, [data, detail, setValue])

    const handleCvFileDrop = (files: any) => {
        if(detail) {
            var objKey = detail.username + "_CV_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("cvFile", objKey)
                        setCvFile(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    const handleProfilePhotoDrop = (files: any) => {
        if(detail) {
            var objKey = detail.username + "_PROFILEPIC_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("profilePhoto", objKey)
                        setProfilePhoto(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    function calcSize(fileSize: number): string {
        if (fileSize < 1000000) {
            return `${Math.round(fileSize/Math.pow(10,3))} KB`
        }
        return `${(fileSize/Math.pow(10,6)).toFixed(1)} MB`
    }

    const getCvFileDropzoneClass = () => {
        if(detail?.cvFile && readOnly) {
            return classes.dropzoneFile
        } else if (readOnly) {
            return `${classes.dropzone} ${classes.disabled}`
        } else {
            return classes.dropzone
        }
    }

    return (
        <div>
            <Card>
                <CardHeader title="Personal Information" action={
                    <Button variant="contained" color="primary" className={classes.editButton} onClick={() => setReadOnly(false)}>
                        Edit
                        <Edit style={{marginLeft: 4}} fontSize="small" />
                    </Button>
                }/>
                <CardContent>
                    <Grid container spacing={2}>

                        <Grid item xs={12} lg={6}>
                            <FormControl
                                className={classes.input}>

                                {((!data?.profilePhoto && readOnly) || !readOnly) ?
                                    <>
                                        <FormLabel className={classes.label}>Upload Profile Photo below.</FormLabel>
                                        <Controller
                                            control={control}
                                            name="profilePhoto"
                                            defaultValue=""
                                            render={() => (
                                            <div>
                        
                                                <Dropzone onDrop={handleProfilePhotoDrop} multiple={false} disabled={readOnly}
                                                accept={[
                                                    'image/jpeg',
                                                    'image/png']}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <Paper
                                                    variant="outlined"
                                                    {...getRootProps({className: (readOnly) ? `${classes.dropzone} ${classes.disabled}` : classes.dropzone})}
                                                    >
                                                    <input {...getInputProps()} name="profilePhoto" />
                                                    <p>Drag 'n' drop files here, or click to select files</p>
                                                    </Paper>
                                                )}
                                                </Dropzone>
                                                {uploadedProfilePhoto.length !== 0 && 
                                                    <List>
                                                    {uploadedProfilePhoto.map((f: File, index: number) => (
                                                    <ListItem key={index}>
                                                        <ListItemIcon>
                                                        <InsertDriveFile />
                                                        </ListItemIcon>
                                                        <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                    </ListItem>
                                                    ))}
                                                </List>
                                                }
                                            </div>
                                            )}
                                        />
                                    </>
                                :
                                    <>
                                        <FormLabel className={classes.label}>Profile Photo</FormLabel>
                                        <a href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${data?.profilePhoto}`}> 
                                            <img style={{height: 246}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${data?.profilePhoto}`} />
                                        </a>
                                    </>
                                }
                                
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={6}>

                            {(detail?.cvFile?.endsWith(".png") || detail?.cvFile?.endsWith(".jpeg") || detail?.cvFile?.endsWith(".jpg")) ?
                                <FormControl style={{marginBottom: 16}}>
                                    <FormLabel className={classes.label}>CV/Resume</FormLabel>
                                    <a href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${detail.cvFile}`}> 
                                        <img style={{height: 246}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${detail?.cvFile}`} />
                                    </a>
                                </FormControl>
                            :
                                <FormControl
                                    className={classes.input}>

                                    {(detail?.cvFile && readOnly) ?
                                        <FormLabel className={classes.label}>CV/Resume</FormLabel>
                                    :
                                        <FormLabel className={classes.label}>Upload CV/Resume below.</FormLabel>
                                    }
                                    <Controller
                                        control={control}
                                        name="cvFile"
                                        defaultValue=""
                                        render={() => (
                                        <div >
                                            
                                            <Dropzone onDrop={handleCvFileDrop} multiple={false} disabled={readOnly}
                                            accept={[
                                                'image/jpeg',
                                                'image/png',
                                                'application/pdf',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                            {({ getRootProps, getInputProps }) => (
                                                <Paper
                                                variant="outlined"
                                                {...getRootProps({className: getCvFileDropzoneClass()})}
                                                >
                                                <input {...getInputProps()} name="cvFile" />
                                                {detail &&
                                                    <>
                                                    {(detail.cvFile && readOnly) ?
                                                        <a href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${detail.cvFile}`}>
                                                            <FontAwesomeIcon style={{color: "#9e9e9e"}} icon={(detail.cvFile.endsWith(".pdf")) ? faFilePdf : faFileWord} size="10x" />
                                                        </a>
                                                    :
                                                        <p>Drag 'n' drop files here, or click to select files</p>
                                                    }
                                                    </>
                                                }
                                                
                                                </Paper>
                                            )}
                                            </Dropzone>
                                            {uploadedCvFile.length !== 0 && 
                                                <List>
                                                {uploadedCvFile.map((f: File, index: number) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                    <InsertDriveFile />
                                                    </ListItemIcon>
                                                    <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                </ListItem>
                                                ))}
                                            </List>
                                            }
                                        </div>
                                        )}
                                    />
                                </FormControl>
                            }
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>
                            <FormControl
                                error={Boolean(errors.title)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}>Title</FormLabel>

                                <Controller
                                    as={
                                        <Select variant="filled"
                                            defaultValue=""
                                            disabled={readOnly}>
                                            <MenuItem value="">Select a Title</MenuItem>
                                            <MenuItem value="Mr">Mr</MenuItem>
                                            <MenuItem value="Miss">Miss</MenuItem>
                                            <MenuItem value="Ms">Ms</MenuItem> 
                                            <MenuItem value="Mrs">Mrs</MenuItem>
                                        </Select>
                                    }
                                    name="title"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                                <FormHelperText>
                                    {errors.title && errors.title.message}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>

                            <FormControl
                                error={Boolean(errors.firstName)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}>First Name</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                        placeholder="Enter first name"
                                        fullWidth
                                        variant="filled"
                                        error={errors.firstName ? true : false}
                                        helperText={errors.firstName?.message}
                                        disabled={readOnly}/>
                                    }
                                    name="firstName"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                    required: "This is required"
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>

                            <FormControl
                                error={Boolean(errors.surname)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}>Surname</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            placeholder="Enter surname"
                                            fullWidth
                                            variant="filled"
                                            error={errors.surname ? true : false}
                                            helperText={errors.surname?.message}
                                            disabled={readOnly}/>
                                    }
                                    name="surname"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                    required: "This is required"
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>


                            <FormControl
                                error={Boolean(errors.address)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}>Address</FormLabel>

                                <PlacesAutocomplete 
                                    value={addressInput}
                                    onChange={(address: string) => {
                                        setAddressInput(address)
                                        clearErrors("address")
                                        setAddressValue(undefined)
                                    }}
                                    onSelect={(_, placeID: string) => {
                                        var request = {
                                            placeId: placeID
                                        };
                                
                                        var placeRequest: Promise<google.maps.places.PlaceResult> = new Promise((resolve, reject) => {
                                            new google.maps.places.PlacesService(document.createElement('div')).getDetails(request, (place, status) => {
                                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                                    resolve(place);
                                                } else { reject() };
                                            });
                                        })
                                        placeRequest.then((result: google.maps.places.PlaceResult) => {
                                            if(result && result?.formatted_address && result?.address_components) {
                                                setAddressInput(result.formatted_address)
                                                setAddressValue(result.formatted_address)

                                                result.address_components.forEach((c) => {
                                                    if(c.types.includes("street_number")) {
                                                        setValue("streetNumber", c.long_name)

                                                    } else if(c.types.includes("route")) {
                                                        setValue("street", c.long_name)

                                                    } else if(c.types.includes("sublocality_level_1")) {
                                                        setValue("suburb", c.long_name)

                                                    } else if(c.types.includes("locality")) {
                                                        setValue("region", c.long_name)
                                                    
                                                    } else if(c.types.includes("country")) {
                                                        setValue("country", c.long_name)

                                                    } else if(c.types.includes("postal_code")) {
                                                        setValue("postcode", c.long_name)

                                                    }
                                                })
                                            }
                                        })
                                        .catch((error: any) => {
                                            console.log("Error: ", error)
                                        })
                                    }}
                                    searchOptions={{
                                        componentRestrictions: { country: ['au', 'nz'] },
                                        types: ['address']
                                    }}
                                    onError={(_, clearSuggestions) => {
                                        clearSuggestions()
                                    }}>

                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                
                                                {...getInputProps({
                                                    placeholder: 'Enter address',
                                                    disabled: readOnly
                                                })}
                                            />
                                            <div className={classes.addressOptions}>
                                            {loading && 
                                                <div style={{backgroundColor: '#ffffff', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa"}}>
                                                    <Typography variant="body1">
                                                        Loading ...
                                                    </Typography>
                                                </div>
                                            }

                                            {suggestions.map((suggestion: any, i: number) => {
                                                let style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" };

                                                if((i === suggestions.length - 1) && !suggestion.active) {
                                                    style = { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "2px solid #fafafa" }
                                                }

                                                return (
                                                    <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                                        <Typography variant="body1">{suggestion.description}</Typography>
                                                    </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                <FormHelperText>
                                    {errors.address && "Invalid address"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>

                            <FormControl
                                error={Boolean(errors.gender)}
                                className={classes.input}>

                                <FormLabel className={classes.label}>Gender</FormLabel>

                                <Controller
                                    as={
                                        <Select variant="filled" 
                                            defaultValue=""
                                            disabled={readOnly}>
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="Male">Male</MenuItem>
                                            <MenuItem value="Female">Female</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    }
                                    name="gender"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                                <FormHelperText>
                                    {errors.gender && errors.gender.message}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>

                            <FormControl
                                error={Boolean(errors.dob)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}>Date of Birth</FormLabel>

                                <Controller
                                    render={({onChange, value}) => (
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    placeholder="DD/MM/YYYY"
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    id="date-picker6"
                                                    value={value}
                                                    onChange={date => onChange(date)}
                                                    disabled={readOnly}/>
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    )}
                                    name="dob"
                                    control={control}
                                    defaultValue={null}
                                    rules={{
                                    required: "This is required"
                                    }}
                                />
                                <FormHelperText>
                                    {errors.dob && errors.dob.message}
                                </FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <FormControl
                                error={Boolean(errors.email)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}>Email</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            placeholder="Enter email"
                                            fullWidth
                                            variant="filled"
                                            error={errors.email ? true : false}
                                            helperText={errors.email?.message}
                                            disabled />
                                    }
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                    required: "This is required"
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>

                            <FormControl 
                                className={classes.input}
                                error={Boolean(errors.phone)}>
                    
                                <FormLabel className={classes.label}>Mobile number</FormLabel>
                    
                                <Controller
                                render={({onChange, value}) => (
                                    <TextField
                                    placeholder="Enter mobile number"
                                    fullWidth
                                    variant="filled"
                                    value={value}
                                    onChange={v => {
                                        onChange(v)
                                        clearErrors("phone")
                                    }}
                                    error={errors.phone ? true : false}
                                    helperText={errors.phone?.message}
                                    disabled={readOnly}/>
                                )}
                                name="phone"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>

                            {/* <FormControl
                                error={Boolean(errors.jobType)} 
                                style={{width: "50%"}}>
                        
                                <FormLabel className={classes.label}>Select Job Type/s</FormLabel>
                        
                                {[{"label": "Permanent", "name": "permanent"}, {"label": "Temporary", "name": "temporary"}].map(item => (
                                    <Controller
                                        key={item.label}
                                        render={(props) => (
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                        }} 
                                                        checked={props.value}
                                                        disabled={readOnly} />
                                                } 
                                                label={item.label} />
                                        )}
                                        name={`jobType.${item.name}`}
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            validate: (value: boolean) => {
                                                if(value) {
                                                    clearErrors("jobType")
                                                    return true
                                                } else {
                                                    let v = watch("jobType")
                                                    if(v) {
                                                        if(v.permanent === false && v.temporary === false) {
                                                            setError("jobType", { message: "At least 1 required"})
                                                            return false
                                                        }
                                                    } else {
                                                        setError("jobType", { message: "At least 1 required"})
                                                        return false
                                                    }
                                                    return true
                                                }
                                                
                                            }
                                        }}
                                    />
                                ))}

                                <FormHelperText>
                                    {errors.jobType && "At least 1 required"}
                                </FormHelperText>
                            </FormControl> */}

                            <FormControl
                                error={Boolean(errors.availability)}
                                style={{width: "50%"}}>

                                <FormLabel className={classes.label}>Availability?</FormLabel>

                                {[{"label": "Temporary", "name": "temporary"}, {"label": "Permanent", "name": "permanent"}, {"label": "Contract", "name": "contract"}, {"label": "All of the Above", "name": "alltheabove"}].map(item => (
                                    <Controller
                                        key={item.label}
                                        render={(props) => (
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox onChange={e => {
                                                        props.onChange(e.target.checked)
                                                    }} 
                                                    checked={props.value}
                                                    disabled={readOnly} />
                                                } 
                                                label={item.label} />
                                        )}
                                        name={`availability.${item.name}`}
                                        control={control}
                                        defaultValue={false}
                                        rules={{
                                            validate: (value: boolean) => {
                                                if(value) {
                                                    clearErrors("availability")
                                                    return true
                                                } else {
                                                    let v = getValues("availability")
                                                    if(v) {
                                                        if(v.temporary === false && v.permanent === false && v.contract === false && v.alltheabove === false) {
                                                            setError("availability", { message: "At least 1 required"})
                                                            return false
                                                        }
                                                    } else {
                                                        setError("availability", { message: "At least 1 required"})
                                                        return false
                                                    }
                                                    return true
                                                }
                                            }
                                        }}
                                    />
                                ))}

                                <FormHelperText>
                                    {errors.availability && "At least 1 required"}
                                </FormHelperText>
                                
                            </FormControl>

                        </Grid>
                        
                    </Grid>
                </CardContent>
                <CardActions style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}/>
                        {!readOnly &&
                            <div>
                                {error &&
                                    <span style={{color: "red", marginRight: 8}}>{error}</span>
                                }

                                <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => {
                                    setReadOnly(true)
                                    refreshCandidateData()
                                    clearErrors()
                                }}>
                                    Cancel
                                    <Clear style={{marginLeft: 4}} fontSize="small" />
                                </Button>
                                {loading ?
                                    <Button variant="contained" color="primary" className={classes.actionButton}>
                                        <CircularProgress size={25} style={{color: "white"}} />
                                    </Button>
                                :
                                    <Button onClick={handleSubmit(onSave)} variant="contained" color="primary" className={classes.actionButton}>
                                        Save
                                        <Save style={{marginLeft: 4}} fontSize="small" />
                                    </Button>
                                }
                            </div>
                        }
                </CardActions>

                {/* <CardActions style={{display: "flex"}}>
                <FormControl
                error={Boolean(errors.transport)}
                className={classes.input}>

                <FormLabel className={classes.label}>Do you have reliable transport?</FormLabel>

                <Controller
                    as={
                        <Select variant="filled"
                            defaultValue=""
                            displayEmpty={true}>
                            <MenuItem value="">Select an option</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    }
                    name="transport"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.transport && errors.transport.message}
                </FormHelperText>
            </FormControl>

            <FormControl
                error={Boolean(errors.criminalConvictions)}
                className={classes.input}>

                <FormLabel className={classes.label}>Do you have any past / pending criminal convictions?</FormLabel>

                <Controller
                    as={
                        <Select variant="filled"
                            defaultValue=""
                            displayEmpty={true}>
                            <MenuItem value="">Select an option</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    }
                    name="criminalConvictions"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.criminalConvictions && errors.criminalConvictions.message}
                </FormHelperText>
            </FormControl>

            {watch("criminalConvictions") === "Yes" &&
                <FormControl
                    error={Boolean(errors.criminalConvictionDetail)}
                    className={classes.input}>

                    <FormLabel className={classes.label}>Please state what the conviction(s) is/are:</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter detail"
                                fullWidth
                                variant="filled"
                                error={errors.criminalConvictionDetail ? true : false}
                                helperText={errors.criminalConvictionDetail?.message} />
                        }
                        name="criminalConvictionDetail"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            }
                </CardActions> */}
            </Card>
        </div>
    )
}
