import React, { useState } from 'react';
import {
    AppBar, CssBaseline, Divider, Drawer, Hidden, IconButton, Toolbar,
    Typography, List, ListItem, ListItemText, ListItemProps, Menu, MenuItem
} from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { 
  AccountCircle, Home, Person, HighlightOff, Work, Lock, Edit
} from "@material-ui/icons"
import { Candidate } from '../CandidateTypes';
import ChangePasswordDialog from './ChangePasswordDialog';
import { MyAuth } from '../hooks/useAuth';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiContainer-root': {
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 8,
          paddingRight: 8
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      }
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: "#259a49"
    },
    drawerButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    menuButton: {
      //marginRight: theme.spacing(2),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      }
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1
      }
    },
    subTitle: {
      fontWeight: "bold",
      marginLeft: 8,
      flexGrow: 1
    },
    drawerItem: {
      marginLeft: 4
    },
    candidateDetails: {
      position: "absolute"
    }
  }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
  }

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactNode;
  candidate?: Candidate;
  auth: MyAuth
}

export default function AppMenu(props: Props) {
  const { window, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [actionsMenu, setActionsMenu] = useState<null | HTMLElement>(null)
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState<boolean>(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserDetailsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsMenu(event.currentTarget)
  }
  
  const signOut = () => {
    //removed saved registration data from local storage
    localStorage.removeItem('personalInfoPart1Form')
    localStorage.removeItem('personalInfoPart2Form')
    localStorage.removeItem('additionalInfoPart1Form')
    localStorage.removeItem('additionalInfoPart2Form')
    localStorage.removeItem('rightToWorkForm')
    localStorage.removeItem('qualificationsForm')
    localStorage.removeItem('workHistory1Form')
    localStorage.removeItem('workHistory2Form')
    props.auth.signOut()
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemLink href="#/portal" onClick={() => setMobileOpen(false)}>
          <Home />
          <ListItemText className={classes.drawerItem} primary="Home" />
        </ListItemLink>
        <ListItemLink href="#/portal/profile" onClick={() => setMobileOpen(false)}>
          <Person />
          <ListItemText className={classes.drawerItem} primary="Profile" />
        </ListItemLink>
        <ListItemLink href="#/portal/jobs" onClick={() => setMobileOpen(false)}>
          <Work />
          <ListItemText className={classes.drawerItem} primary="Jobs" />
        </ListItemLink>
        <ListItemLink href="#/portal/data-privacy" onClick={() => setMobileOpen(false)}>
          <Lock />
          <ListItemText className={classes.drawerItem} primary="Data & Privacy" />
        </ListItemLink>
      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.drawerButton}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Lowie Recruitment
          </Typography>
          <Hidden smDown>
            <Typography className={classes.subTitle} variant="h6" noWrap>
              Candidate Portal
            </Typography>
          </Hidden>
          <div>
          <IconButton className={classes.menuButton} color="inherit" onClick={handleUserDetailsClick}>
            <AccountCircle />
          </IconButton>
          <Menu 
            open={Boolean(actionsMenu)}
            anchorEl={actionsMenu}
            onClose={() => setActionsMenu(null)}>

            <MenuItem onClick={() => {
              setActionsMenu(null)
              signOut()
            }}>
              <HighlightOff style={{marginRight: 4}}/>
              Sign Out
            </MenuItem>

            <MenuItem onClick={() => {
              setActionsMenu(null)
              setOpenChangePasswordDialog(true)
            }}>
              <Edit style={{marginRight: 4}}/>
              Change Password
            </MenuItem>

          </Menu>
          <Hidden xsDown>
            {props.candidate !== undefined &&
              <span style={{fontWeight: "bold"}}>{`${props.candidate.firstName} ${props.candidate.surname}`}</span> 
            }
          </Hidden>
            
          </div>
          
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>

      <ChangePasswordDialog
        open={openChangePasswordDialog}
        handleDialog={setOpenChangePasswordDialog}
        auth={props.auth}
      />
    </div>
  );
}