import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import AMPInductionQuizComponent from '../documents/AMP/AMPInductionQuizComponent';
import { CandidateActionsInfo } from '../CandidateTypes';

interface Props {
    action?: CandidateActionsInfo;
    candidateId?: string;
}

export default function Forms({ action, candidateId }: Props) {

    let { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/am-induction-quiz`}>
                <AMPInductionQuizComponent formData={action} candidateId={candidateId} />
            </Route>
            <Route exact path={path}>
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}
