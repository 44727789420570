import { format, parseISO } from 'date-fns';

export const FormatDateTime = (date: string): string => {
  if(IsValidDate(date)) {
    return format(parseISO(date), 'HH:mm dd/MM/yyyy')
  }
  return ""
    
}

export const FormatDate = (date: string): string => {
  if(IsValidDate(date)) {
    return format(parseISO(date), 'dd/MM/yyyy')
  }
  return ""
}

export const FormatTime = (time: string) : string => {
  return format(parseISO(time), 'hh:mm a')
}

export const IsValidDate = (dateString: Date | string) => {
    var day = 0;
    var month = 0;
    var year = 0;
    if(dateString instanceof Date) {
      // First check for the pattern
      if(!/^\w{3} \w{3} \d{2} \d{4}/.test(dateString.toString())) {
        return false;
      }

      // Parse the date parts to integers
      var parts = dateString.toString().split(" ");
      day = parseInt(parts[2], 10);
      var monthStr = parts[1];
      year = parseInt(parts[3], 10);

      switch (monthStr) {
        case "Jan":
          month = 1
          break;
        case "Feb":
          month = 2
          break;
        case "Mar":
          month = 3
          break;
        case "Apr":
          month = 4
          break;
        case "May":
          month = 5
          break;
        case "Jun":
          month = 6
          break;
        case "Jul":
          month = 7
          break;
        case "Aug":
          month = 8
          break;
        case "Sep":
          month = 9
          break;
        case "Oct":
          month = 10
          break;
        case "Nov":
          month = 11
          break;
        case "Dec":
          month = 12
          break;
      }
    } else {
      //"2021-03-03T13:00:00.000Z"
      if(dateString) {
        if(!/^\d{4}-\d{2}-\d{2}/.test(dateString.toString())) {
          return false;
        }
  
        // Parse the date parts to integers
        parts = dateString.toString().split("-");
        day = parseInt(parts[2], 10);
        month = parseInt(parts[1], 10);
        year = parseInt(parts[0], 10);
      } else {
        return false;
      }
    }

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month === 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}