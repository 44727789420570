import React, { useEffect, useState } from "react";
import {
  CircularProgress, Button, Card, CardActions
} from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons"
import { MyAuth } from "../hooks/useAuth";
import { API } from "aws-amplify";
import { useHistory } from 'react-router-dom';

interface ApplyProps {
    auth: MyAuth
}

export default function Apply({ auth }: ApplyProps) {

    const [applicationLoading, setApplicationLoading] = useState<boolean>(false)
    const [applicationSuccessful, setApplicationSuccessful] = useState<boolean>(false)

    const history = useHistory()

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.hash.replace("#", "?"));

        const jobRef = urlParams.get('/apply?jobRef');

        if(jobRef) {
            setApplicationLoading(true)
            let params = {
                body: {
                    username: auth.user.username,
                    jobRef: jobRef,
                    data: auth.candidate
                }
            }
            API.post("", `/candidates/apply`, params)
            .then(() => {
                setApplicationSuccessful(true)
                setApplicationLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: applying candidate for job", error)
                setApplicationLoading(false)
            })
        } else {
            history.replace("/portal")
        }
    }, [])
      
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 32,
            height: "100vh",
          }}>

            <div>
                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 32
                  }}>
                      <img
                          src="images/lowie-logo-1.png"
                          alt="Lowie Recruitment"
                          width={300}
                      />
                  </div>

                {applicationLoading ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <div>
                            <CircularProgress color="primary" size={120} />
                            <h2 style={{marginTop: 16}}>Loading ...</h2>
                        </div>
                    </div>
                :
                    <div>
                        {applicationSuccessful ?
                            <Card>
                                <div style={{padding: 32}}>
                                    <div style={{display: "flex"}}>
                                        <CheckCircle style={{color: "#4caf50", marginRight: 8}} fontSize="large" />
                                        <h3>Application Successful</h3>
                                    </div>

                                    <p style={{marginTop: 16}}>Thank you {auth.candidate?.firstName} {auth.candidate?.surname} for applying with Lowie.</p>

                                </div>
                                <CardActions  style={{display: "flex", padding: 16}}>
                                    <div style={{flexGrow: 1}}></div>
                                    <div>
                                        <Button variant="contained" color="primary" href="#/portal">
                                            Continue
                                        </Button>
                                        <Button variant="contained" color="primary" style={{marginLeft: 8}} href="#/portal/jobs">
                                            View more Jobs
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        :
                            <Card>
                                <div style={{padding: 32}}>
                                    <div style={{display: "flex"}}>
                                        <Cancel style={{color: "#f44336", marginRight: 8}} fontSize="large" />
                                        <h3>Application Failed</h3>
                                    </div>

                                    <p style={{marginTop: 16}}>We're sorry but we were unable to process your application.</p>
                                </div>
                                <CardActions  style={{display: "flex", padding: 16}}>
                                    <div style={{flexGrow: 1}}></div>
                                    <div>
                                        <Button variant="contained" color="primary" href="#/portal">
                                            Ok
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
