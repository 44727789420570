import React from 'react'
import { 
    Switch, Route, useRouteMatch
} from "react-router-dom";
import Home from "./routes/Home"
import IndustrialTest from "./routes/IndustrialTest"
import JobPlacement from "./routes/job-placement/JobPlacement";
import Jobs from "./routes/jobs/Jobs";
import Profile from "./routes/profile/Profile";
import DataPrivacy from './routes/DataPrivacy';
import RegistrationRoute from './routes/registration/RegistrationRoute';
import { MyAuth } from './hooks/useAuth';
import PortalRoute from './components/PortalRoute';

interface Props {
    auth: MyAuth
}

export default function Portal({auth}: Props) {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <PortalRoute path={`${path}/data-privacy`} >
                <DataPrivacy candidate={auth.candidate} />
            </PortalRoute>
            <PortalRoute path={`${path}/industrial-items`}>
                <IndustrialTest candidate={auth.candidate} />
            </PortalRoute>
            <PortalRoute path={`${path}/job-placement`}>
                <JobPlacement candidate={auth.candidate} />
            </PortalRoute>
            <PortalRoute path={`${path}/registration-process`}>
                <RegistrationRoute candidate={auth.candidate} />
            </PortalRoute>
            <PortalRoute path={`${path}/jobs`}>
                <Jobs candidate={auth.candidate} />
            </PortalRoute>
            <PortalRoute path={`${path}/profile`}>
                <Profile candidate={auth.candidate} />
            </PortalRoute>
            <PortalRoute path={`${path}/`}>
                <Home auth={auth} />
            </PortalRoute>
        </Switch>
    )
}
