import React, { useEffect, useState } from 'react'
import { Route, useRouteMatch } from 'react-router'
import WelcomeDialog from './WelcomeDialog';

interface Props {
    children: React.ReactNode
    path: string
}

export default function PortalRoute({ children, ...rest}: Props) {

    const [openWelcomeDialog, setOpenWelcomeDialog] = useState<boolean>(false)
    let { path } = useRouteMatch();

    useEffect(() => {
        if(window) {
            window.scrollTo(0, 0)
        }
    }, [window])

    useEffect(() => {
        let welcomeDialog = localStorage.getItem("lowie-welcome-dialog")
        if(welcomeDialog) {
            setOpenWelcomeDialog(true)
            localStorage.removeItem("lowie-welcome-dialog")
        }
    }, [path])

    return (
        <Route
            {...rest}
            render={() => (
                <>
                    {children}
                    <WelcomeDialog open={openWelcomeDialog} handleDialog={setOpenWelcomeDialog} />
                </>
            )}
        />
    )
}
