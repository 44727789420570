import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button, Dialog, DialogActions, DialogTitle, IconButton,
  DialogContentText, DialogContent, TextField, Grid,
  Card, CardContent, CardActions, Accordion, CircularProgress,
  AccordionSummary, AccordionDetails, FormControl, FormLabel, FormHelperText,
  Checkbox, FormControlLabel
} from "@material-ui/core"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Add, Edit, Delete, ExpandMore } from "@material-ui/icons"
import { useForm, Controller } from "react-hook-form";
import { WorkExperienceForm, WorkHistoryInfo } from '../../CandidateTypes';
import { API } from "aws-amplify";
import { FormatDate, IsValidDate } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      display: "flex",
      marginBottom: 16
    },
    title: {
      flexGrow: 1
    },
    addButton: {
      backgroundColor: "#00c853",
      marginRight: 8
    },
    cardDetail: {
      marginLeft: 8
    },
    cardActions: {
      float: "right"
    },
    cardContent: {
      paddingBottom: 0
    },
    textInput: {
      marginBottom: theme.spacing(3),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    label: {
      marginBottom: 6
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    editButton: {
      width: 100,
      marginRight: 8
    },
    actionButton: {
      marginRight: 8,
      marginBottom: 8,
      width: 100
    }
  }),
);

interface Props {
  data?: WorkHistoryInfo
  candidate?: string
  refreshCandidateData: () => void;
}

const WorkHistoryCard = ({ data, candidate, refreshCandidateData }: Props) => {
  const classes = useStyles()
 
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [selectedWorkHistory, setSelectedWorkHistory] = useState<WorkExperienceForm>()
  const [workHistoryEditIdx, setWorkHistoryEditIdx] = useState<number>();

  return (
    <div>
        <Accordion style={{marginTop: 32}}> 
            <AccordionSummary
                expandIcon={<ExpandMore />}>
                <h4>Work History & Experience</h4>
            </AccordionSummary>
            <AccordionDetails>
            <div style={{width: "100%"}}>
                <div style={{display: "flex", marginBottom: 24}}>
                    <div style={{flexGrow: 1}}>
                        <p>Enter your work history details.</p>
                    </div>
                    <div>
                        <Button variant="contained" className={classes.addButton} onClick={() => {
                            setOpen(true)
                            setSelectedWorkHistory(undefined)
                          }}>
                            Add History
                            <Add style={{marginLeft: 4}} fontSize="small" />
                        </Button>
                    </div>
                </div>
                    <div>
                      {data &&
                        <div>
                          {!data.workHistory && 
                              <p>No records entered.</p>
                          }
                          {data.workHistory &&
                            <Grid container spacing={3}>
                              {data.workHistory.length !== 0 && data.workHistory.map((job: WorkExperienceForm, i: number) => (
                              <Grid item xs={12} sm={6} lg={4} key={i}>
                                  <Card raised>
                                    <CardContent className={classes.cardContent}>
                                        <div style={{fontWeight: "bold"}}>
                                          Position:
                                        </div>
                                        <div>{job.position}</div>
                                        <div style={{marginTop: 8, fontWeight: "bold"}}>Company:</div>
                                        <div>{job.company}</div>
                                        <div style={{marginTop: 8, fontWeight: "bold"}}>From:</div>
                                        {job.currentJob ?
                                          <div>{FormatDate(job.startDate!!)} - Current</div>
                                        :
                                          <div>{FormatDate(job.startDate!!)} - {FormatDate(job.endDate!!)}</div>
                                        }
                                        
                                    </CardContent>
                                    <CardActions className={classes.cardActions}>
                                        <div>
                                        <IconButton onClick={() => {
                                          setSelectedWorkHistory(job)
                                          setWorkHistoryEditIdx(i)
                                          setOpen(true)
                                        }}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => {
                                          setWorkHistoryEditIdx(i)
                                          setOpenDeleteDialog(true)
                                        }}>
                                          <Delete />
                                        </IconButton>
                                        </div>
                                    </CardActions>
                                  </Card>
                              </Grid>
                              ))}
                          </Grid>
                          }
                        </div>
                      }
                    </div>
                  </div>
            </AccordionDetails>
        </Accordion>

        <AddHistoryDialog 
          open={open}
          handleDialog={setOpen}
          workHistoryInfo={data}
          candidate={candidate}
          refreshCandidateData={refreshCandidateData}
          selectedWorkHistory={selectedWorkHistory}
          index={workHistoryEditIdx} />

        <DeleteHistoryDialog 
          open={openDeleteDialog}
          handleDialog={setOpenDeleteDialog}
          workHistoryInfo={data}
          candidate={candidate}
          refreshCandidateData={refreshCandidateData}
          index={workHistoryEditIdx} />
    </div>
  );
};
export default WorkHistoryCard;

interface AddHistoryDialogProps {
  open: boolean;
  handleDialog: (open: boolean) => void;
  workHistoryInfo?: WorkHistoryInfo;
  candidate?: any | null;
  refreshCandidateData: () => void;
  selectedWorkHistory?: WorkExperienceForm;
  index?: number;
}

const AddHistoryDialog = ({ open, handleDialog, workHistoryInfo, candidate, refreshCandidateData, selectedWorkHistory, index }: AddHistoryDialogProps) => {

  const classes = useStyles()
  const { handleSubmit, control, errors, watch, reset, setError, setValue, clearErrors } = useForm<WorkExperienceForm>({
    shouldUnregister: false
  });

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setAPIError] = useState<string>()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  useEffect(() => {
    clearErrors()
    if(selectedWorkHistory) {
      reset(selectedWorkHistory)
      setIsEdit(true)
    } else {
      setValue("position", "")
      setValue("company", "")
      setValue("startDate", null)
      setValue("endDate", null)
      setIsEdit(false)
    }
  }, [selectedWorkHistory])

  const onSubmit = (data: WorkExperienceForm) => {
    if(candidate && workHistoryInfo) {
      
      let start = new Date(data.startDate!!)
      let end = new Date(data.endDate!!)
      
      if(start < end || data.currentJob) {
        setLoading(true)
        if(data.id === undefined) {
          data.id = workHistoryInfo.workHistory.length + 1
          workHistoryInfo.workHistory.push(data)
        }
  
        if(index !== undefined) {
          workHistoryInfo.workHistory[index] = data
        }
        const params = {
            body: {
                data: workHistoryInfo,
                section: "workHistory"
            }
        };
        API.post("", `/candidates/${candidate}/profile/update`,params)
        .then(() => {
            setLoading(false);
            refreshCandidateData()
        })
        .catch((error: any) => {
            console.log("ERROR adding candidate work history", error);
            setLoading(false)
            setAPIError("Error: unable to update info")
        })  
      } else {
        setError("startDate", {message: "Start date must be less than end date"})
        setError("endDate", {message: "End date must be greater than start date"})
      }
    }
  }

  return (
    <Dialog open={open} onClose={() => handleDialog(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Work History</DialogTitle>
      <DialogContent>
          <DialogContentText>
            Fill out work history details below.
          </DialogContentText>

          <FormControl
            error={Boolean(errors.position)} 
            className={classes.input}>

            <FormLabel className={classes.label}>Position</FormLabel>

            <Controller
                as={
                    <TextField
                        placeholder="Enter position"
                        fullWidth
                        variant="filled"
                        error={errors.position ? true : false}
                        helperText={errors.position?.message}/>
                }
                name="position"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required"
                }}
            />
          </FormControl>

            <FormControl
              error={Boolean(errors.company)} 
              className={classes.input}>

              <FormLabel className={classes.label}>Company</FormLabel>

              <Controller
                  as={
                      <TextField
                          placeholder="Enter company"
                          fullWidth
                          variant="filled"
                          error={errors.company ? true : false}
                          helperText={errors.company?.message}/>
                  }
                  name="company"
                  control={control}
                  defaultValue=""
                  rules={{
                  required: "This is required"
                  }}
              />
            </FormControl>

            <FormControl
              error={Boolean(errors.startDate)} 
              className={classes.input}>

              <FormLabel className={classes.label}>Start Date</FormLabel>

              <Controller
                  render={({onChange, value}) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container>
                              <KeyboardDatePicker
                                  autoOk
                                  placeholder="Enter start date"
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker6"
                                  value={value}
                                  onChange={date => {
                                    onChange(date)
                                    clearErrors()
                                  }}/>
                          </Grid>
                      </MuiPickersUtilsProvider>
                  )}
                  name="startDate"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: "This is required",
                    validate: IsValidDate
                  }}
              />
              <FormHelperText>
                  {errors.startDate && errors.startDate.message}
              </FormHelperText>
          </FormControl>

          {!watch("currentJob") &&

            <FormControl
              error={Boolean(errors.endDate)} 
              style={{width: "100%"}}>

                <FormLabel className={classes.label}>End Date</FormLabel>
                  <Controller
                    render={({onChange, value}) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    autoOk
                                    placeholder="Enter end date"
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker7"
                                    value={value}
                                    onChange={date => {
                                      onChange(date)
                                      clearErrors("endDate")
                                    }}/>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    )}
                    name="endDate"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "This is required",
                      validate: IsValidDate
                    }}
                  />

                <FormHelperText>
                    {errors.endDate && errors.endDate.message}
                </FormHelperText>
              </FormControl>
        }
                
        <FormControl
          className={classes.input}>

          <Controller
              render={(props) => (
                  <FormControlLabel 
                      control={
                          <Checkbox
                              onChange={e => {
                                  props.onChange(e.target.checked)
                                  clearErrors("endDate")
                              }} 
                              checked={props.value} />
                      } 
                      label="I currently work here" />
              )}
              name="currentJob"
              control={control}
              defaultValue={false}
            />
        </FormControl>

      </DialogContent>
      <DialogActions>
        {error &&
            <span style={{color: "red", marginRight: 8}}>{error}</span>
        }
        <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => handleDialog(false)}>
            Cancel
        </Button>
        {loading ?
            <Button variant="contained" color="primary" className={classes.actionButton}>
                <CircularProgress size={25} style={{color: "white"}} />
            </Button>
        :
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" className={classes.actionButton}>
                {isEdit ?
                  <span>Edit</span>
                :
                  <span>Add</span>
                }
            </Button>
        }
      </DialogActions>
      </Dialog>
  )
}

interface DeleteWorkHistoryDialogProps {
  open: boolean;
  handleDialog: (open: boolean) => void;
  workHistoryInfo?: WorkHistoryInfo;
  candidate?: any | null;
  refreshCandidateData: () => void;
  index?: number
}

const DeleteHistoryDialog = ({ open, handleDialog, workHistoryInfo, candidate, refreshCandidateData, index }: DeleteWorkHistoryDialogProps) => {

  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const onSubmit = () => {
    if(candidate && workHistoryInfo && (index !== undefined)) {
      setLoading(true)

      workHistoryInfo.workHistory = workHistoryInfo.workHistory.filter((_: WorkExperienceForm, i: number) => i !== index)

      const params = {
          body: {
              data: workHistoryInfo,
              section: "workHistory"
          }
      };
      API.post("", `/candidates/${candidate}/profile/update`,params)
      .then(() => {
          setLoading(false);
          refreshCandidateData()
      })
      .catch((error: any) => {
          console.log("ERROR deleting candidate work history", error);
          setLoading(false)
          setError("Error: unable to update info")
      })  
    }
  }

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete Work History</DialogTitle>
      <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this work history?
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        {error &&
            <span style={{color: "red", marginRight: 8}}>{error}</span>
        }
        <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => handleDialog(false)}>
            No
        </Button>
        {loading ?
            <Button variant="contained" color="primary" className={classes.actionButton}>
                <CircularProgress size={25} style={{color: "white"}} />
            </Button>
        :
            <Button onClick={() => onSubmit()} variant="contained" color="primary" className={classes.actionButton}>
                Yes
            </Button>
        }
      </DialogActions>
      </Dialog>
  )
}
