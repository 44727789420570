import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
    Typography, Card, Button, CircularProgress, Hidden
} from "@material-ui/core"
import {
    LocationOn, AccountBalanceWallet, DateRange
} from "@material-ui/icons"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Job, calcSalary, calculateExpiryDate, ApplyDialog } from './Jobs';
import "./JobCarousel.css";
import moment from 'moment';
import { Candidate } from "../../CandidateTypes";
import { API } from "aws-amplify"; 

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        jobInfo: {
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap',
        },
        jobInfoIcon: {
            marginRight: 4
        },
        carousel: {
            padding: "0px 40px",
            [theme.breakpoints.only('xs')]: {
                width: 350
            },
            [theme.breakpoints.only('sm')]: {
                width: 550
            },
            [theme.breakpoints.only('md')]: {
                width: 750
            },
            [theme.breakpoints.up('lg')]: {
                width: 1100
            },
        },
        title: {
            textTransform: 'uppercase',
            height: 65
        }
    })
);

interface Props {
    similarJobs: Array<Job>
    jobs: Array<Job>
    candidate: Candidate | undefined
}

export default function JobCarousel({ similarJobs, jobs, candidate }: Props) {

    const classes = useStyles()

    const history = useHistory()


    const [applyLoading, setApplyLoading] = useState<boolean>(false)
    const [applySuccessful, setApplySuccessful] = useState<boolean>(false)
    const [openApplyDialog, setOpenApplyDialog] = useState<boolean>(false)
    const [selectedJob, setSelectedJob] = useState<Job>()

    var settings = {
        dots: true,
        infinite: similarJobs.length > 3,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: similarJobs.length > 2,
                    speed: 500
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: similarJobs.length > 1,
                    speed: 500,
                    dots: false
                }
            },
        ]
    };

    const calculatePostedDuration = (timestamp: string) : string => {
        let timeInSecs = parseInt(timestamp)
        let date = new Date(0);
        date.setUTCSeconds(timeInSecs)
        let now = moment(new Date())
        return moment.duration(-now.diff(date)).humanize(true)
    }

    const getCurrentJobIndex = (jobRef: string): number => {
        for(let i = 0; i < jobs.length; i++) {
            if(jobs[i].Reference === jobRef) {
                return i
            }
        }
        return -1
    }

    const applyCandidateForJob = (jobRef: string) => {
        if(candidate) {
            setApplyLoading(true)
            let params = {
                body: {
                    username: candidate.username,
                    jobRef: jobRef,
                    data: candidate
                }
            }
            API.post("", `/candidates/apply`, params)
            .then(() => {
                setApplySuccessful(true)
                setApplyLoading(false)
                setOpenApplyDialog(true)
            })
            .catch((error: any) => {
                console.log("Error: applying candidate for job", error)
                setApplySuccessful(false)
                setApplyLoading(false)
                setOpenApplyDialog(true)

            })
        } else {
            setApplySuccessful(false)
            setOpenApplyDialog(true)
        }
    }

    const getTitle = (title: string) : string => {
        if(title.length > 30) {
            return title.substring(0, 31) + "..."
        }
        return title
    }

    const getTitleMobile = (title: string) : string => {
        if(title.length > 19) {
            return title.substring(0, 20) + "..."
        }
        return title
    }

    return (
        <div className={classes.carousel}>
            <Slider {...settings}>
                {similarJobs.length !== 0 && similarJobs.map((job: Job, i: number) => (
                    <div key={i}>
                        <p style={{marginBottom: 4}}>Posted {calculatePostedDuration(job.timestamp)}</p>
                        <Hidden xsDown >
                            <Card style={{backgroundImage: "linear-gradient(#95c54b, #259a49)", padding: 16}} raised>
                                <div style={{margin: 16, color: "white"}}>
                                    <Typography variant="h6" gutterBottom className={classes.title}>
                                        {getTitle(job.Title)}
                                    </Typography>
                                    <div className={classes.jobInfo}> 
                                        <LocationOn className={classes.jobInfoIcon} fontSize="large"/> 
                                        <Typography variant="h6">
                                            {job.Location}
                                        </Typography>
                                    </div> 
                                    <div className={classes.jobInfo}> 
                                        <AccountBalanceWallet className={classes.jobInfoIcon} fontSize="large"/> 
                                        <Typography variant="h6">
                                            {calcSalary(job)}
                                        </Typography>
                                    </div> 
                                    <div className={classes.jobInfo}> 
                                        <DateRange className={classes.jobInfoIcon} fontSize="large"/> 
                                        <Typography variant="h6">
                                            {calculateExpiryDate(job.timestamp)}
                                        </Typography>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: 16}}>
                                        <Button 
                                            variant="outlined" 
                                            style={{color: "white", borderColor: "white"}}
                                            onClick={() => history.push({pathname: `/portal/jobs/${job.Reference}`, state: {jobs: jobs, currentJobIdx: getCurrentJobIndex(job.Reference)}})}>
                                            Read More
                                        </Button>
                                        <Button variant="contained" style={{backgroundColor: "white", color: "#259a49"}}
                                            onClick={() => {
                                                setSelectedJob(job)
                                                applyCandidateForJob(job.Reference)
                                            }}>
                                            {applyLoading ?
                                                <CircularProgress size={25} color="primary" />
                                            :
                                                <span>Apply Now</span>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Hidden>
                        <Hidden smUp>
                                <Card style={{backgroundImage: "linear-gradient(#95c54b, #259a49)", padding: 8}} raised>
                                <div style={{margin: 8, color: "white"}}>
                                    <Typography variant="h6" gutterBottom className={classes.title}>
                                        {getTitleMobile(job.Title)}
                                    </Typography>
                                    <div className={classes.jobInfo}> 
                                        <LocationOn className={classes.jobInfoIcon} fontSize="large"/> 
                                        <Typography variant="subtitle1">
                                            {job.Location}
                                        </Typography>
                                    </div> 
                                    <div className={classes.jobInfo}> 
                                        <AccountBalanceWallet className={classes.jobInfoIcon} fontSize="large"/> 
                                        <Typography variant="subtitle1">
                                            {calcSalary(job)}
                                        </Typography>
                                    </div> 
                                    <div className={classes.jobInfo}> 
                                        <DateRange className={classes.jobInfoIcon} fontSize="large"/> 
                                        <Typography variant="subtitle1">
                                            {calculateExpiryDate(job.timestamp)}
                                        </Typography>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: 16}}>
                                        <Button 
                                            variant="outlined" 
                                            style={{color: "white", borderColor: "white"}}
                                            onClick={() => history.push({pathname: `/portal/jobs/${job.Reference}`, state: {jobs: jobs, currentJobIdx: getCurrentJobIndex(job.Reference)}})}>
                                            Read More
                                        </Button>
                                        <Button variant="contained" style={{backgroundColor: "white", color: "#259a49"}}
                                            onClick={() => {
                                                setSelectedJob(job)
                                                applyCandidateForJob(job.Reference)
                                            }}>
                                            {applyLoading ?
                                                <CircularProgress size={25} color="primary" />
                                            :
                                                <span>Apply Now</span>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Hidden>
                    </div>
                ))}
            </Slider>

            <ApplyDialog
                open={openApplyDialog}
                handleDialog={setOpenApplyDialog}
                success={applySuccessful}
                job={selectedJob} />
        </div>
       
    )
}
