import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Grid, FormControl, FormLabel, FormHelperText, Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useForm, Controller } from "react-hook-form";
import { API } from "aws-amplify";
import { Candidate, WorkExperienceForm } from '../../CandidateTypes';
import { IsValidDate } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      marginBottom: 16
    },
    title: {
      flexGrow: 1
    },
    label: {
      marginBottom: 6
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    }
  }),
);
interface Props {
  candidate?: Candidate
  activeStep: number;
  incrementActiveStep: () => void
  incrementActiveStepTwice: () => void
}

const WorkHistoryPart1 = ({ candidate, activeStep, incrementActiveStep, incrementActiveStepTwice }: Props) => {
  const classes = useStyles()

  const [isLoading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, watch, errors, clearErrors, setError, getValues, reset } = useForm<WorkExperienceForm>({
    shouldUnregister: false
  });

  useEffect(() => {
    let jsonForm = localStorage.getItem('workHistory1Form')
    if(jsonForm) {
        let form: WorkExperienceForm = JSON.parse(jsonForm)
        reset(form)
    }
  }, [])

  const handleNext = (data: WorkExperienceForm) => {
    if(candidate) {
      if(!data.noExperience) {
        let start = new Date(data.startDate!!)
        let end = new Date(data.endDate!!)

        if (start < end || data.currentJob) {
          setLoading(true)
          data.id = 1

          localStorage.setItem('workHistory1Form', JSON.stringify(data))

          const params = {
              body: {
                  data: {
                    workHistory: [data]
                  },
                  step: activeStep
              }
          };
          API.post("", `/candidates/${candidate?.username}/registration`,params)
          .then(() => {
              setLoading(false);
              incrementActiveStep()
          })
          .catch((error: any) => {
              console.log("ERROR adding candidate work history", error);
              setLoading(false)
          })  
        } else {
          setError("startDate", {message: "Start date must be less than end date"})
          setError("endDate", {message: "End date must be greater than start date"})
        }
      } else {
        
        setLoading(true)
        localStorage.setItem('workHistory1Form', JSON.stringify(data))

        const params = {
          body: {
            data: [],
            step: activeStep+1
          }
        };
        API.post("", `/candidates/${candidate?.username}/registration`,params)
        .then(() => {
            setLoading(false);
            incrementActiveStepTwice()
        })
        .catch((error: any) => {
            console.log("ERROR adding candidate work history", error);
            setLoading(false)
        })  
      }
    }
  }

  return (
    <div>
      <div className={classes.header}>
        <h2 className={classes.title}>Work History & Experience</h2>
      </div>
      
      <p style={{marginBottom: 24}}>Enter your work history details.</p>

      <FormControl
        className={classes.input}>

        <Controller
            render={(props) => (
                <FormControlLabel 
                    control={
                        <Checkbox
                            onChange={e => {
                                props.onChange(e.target.checked)
                                clearErrors()
                            }} 
                            checked={props.value} />
                    } 
                    label="I have no previous work experience" />
            )}
            name="noExperience"
            control={control}
            defaultValue={false}
        />
      </FormControl>

      {!watch("noExperience") &&
        <>
          <FormControl
            error={Boolean(errors.position)} 
            className={classes.input}>

            <FormLabel className={classes.label}>Position</FormLabel>

            <Controller
                as={
                    <TextField
                        placeholder="Enter position"
                        fullWidth
                        variant="filled"
                        error={errors.position ? true : false}
                        helperText={errors.position?.message}/>
                }
                name="position"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required"
                }}
            />
          </FormControl>

            <FormControl
              error={Boolean(errors.company)} 
              className={classes.input}>

              <FormLabel className={classes.label}>Company</FormLabel>

              <Controller
                  as={
                      <TextField
                          placeholder="Enter company"
                          fullWidth
                          variant="filled"
                          error={errors.company ? true : false}
                          helperText={errors.company?.message}/>
                  }
                  name="company"
                  control={control}
                  defaultValue=""
                  rules={{
                  required: "This is required"
                  }}
              />
            </FormControl>

            <FormControl
              error={Boolean(errors.startDate)} 
              className={classes.input}>

              <FormLabel className={classes.label}>Start Date</FormLabel>

              <Controller
                  render={({onChange, value}) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container>
                              <KeyboardDatePicker
                                  autoOk
                                  placeholder="Enter start date"
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker6"
                                  value={value}
                                  onChange={date => {
                                    onChange(date)
                                    clearErrors("startDate")
                                  }}/>
                          </Grid>
                      </MuiPickersUtilsProvider>
                  )}
                  name="startDate"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: "This is required",
                    validate: IsValidDate
                  }}
              />
              <FormHelperText>
                  {errors.startDate && errors.startDate.message}
              </FormHelperText>
          </FormControl>
            
          {!watch("currentJob") &&

            <FormControl
              error={Boolean(errors.endDate)} 
              style={{width: "100%"}}>

                <FormLabel className={classes.label}>End Date</FormLabel>
                  <Controller
                    render={({onChange, value}) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    autoOk
                                    placeholder="Enter end date"
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker7"
                                    value={value}
                                    onChange={date => {
                                      onChange(date)
                                      clearErrors("endDate")
                                    }}/>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    )}
                    name="endDate"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "This is required",
                      validate: IsValidDate
                    }}
                  />

                <FormHelperText>
                    {errors.endDate && errors.endDate.message}
                </FormHelperText>
              </FormControl>
        }
                
        <FormControl
          className={classes.input}>

          <Controller
              render={(props) => (
                  <FormControlLabel 
                      control={
                          <Checkbox
                              onChange={e => {
                                  props.onChange(e.target.checked)
                                  clearErrors("endDate")
                              }} 
                              checked={props.value} />
                      } 
                      label="I currently work here" />
              )}
              name="currentJob"
              control={control}
              defaultValue={false}
            />
        </FormControl>
          
        </>
      }

      <div style={{marginTop: 16}}>
        <Button variant="contained" color="primary" onClick={handleSubmit(handleNext)} disabled={isLoading}>
            Next
        </Button>
      </div>
    </div>
  );
};
export default WorkHistoryPart1;