import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { 
  TextField, Select, MenuItem, Button, Paper, Grid, ListSubheader,
  FormControl, FormHelperText, FormLabel,
  Card, List, ListItem, ListItemIcon, ListItemText, CircularProgress
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form"
import Dropzone from 'react-dropzone'
import { InsertDriveFile } from '@material-ui/icons'
import { API, Storage } from "aws-amplify";
import { Candidate } from '../../CandidateTypes';
import isMobilePhone from "validator/lib/isMobilePhone";
import { MyAuth } from '../../hooks/useAuth';
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    registerPage: {
      [theme.breakpoints.up('sm')]: {
        display: "flex",
        justifyContent: "center",
        marginTop: 32,
        height: "100vh"
      },
      [theme.breakpoints.down('xs')]: {
        padding: "24px 0px"
      }
    },
    submit: {
      marginTop: theme.spacing(3),
      width: 100
    },
    input: {
      width: "100%"
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 75,
      paddingBottom: 75,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out"
    },
    dropzoneError: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "red",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out"
    },
    label: {
      marginBottom: 6
    },
    error: {
      '&.MuiFormHelperText-contained': {
        marginLeft: 0
      }
    }, 
    complete: {
      display: "flex",
      justifyContent: "center",
      marginTop: 64
    },
    form: {
      margin: "0 auto", 
      [theme.breakpoints.up('md')]: {
        width: 600
      }
    }
  }),
);

type Inputs = {
  title: string;
  firstName: string;
  surname: string;
  phone: string;
  email: string;
  industry: string;
  industryOther: string;
  location: string;
  uploadCv: string;
  cvFile: string;
};

interface Props {
  auth: MyAuth
}

export default function Registration({ auth }: Props) {
  const classes = useStyles()

  const [uploadedCvFile, setUploadedCvFile] = useState<Array<File>>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [registerLoading, setRegisterLoading] = useState<boolean>(false)

  const { handleSubmit, watch, control, errors, setValue, setError, clearErrors } = useForm<Inputs>({
    defaultValues: {
      title: "",
      firstName: auth.user?.attributes?.given_name || "",
      surname: auth.user?.attributes?.family_name || "",
      phone: "",
      email: auth.user?.attributes?.email || "",
      industry: "",
      industryOther: "",
      location: "",
      cvFile: ""
    }
  });

  const watchIndustry = watch("industry")

  const onSubmit = (data: Inputs) => {
    data.phone = data.phone.replace(/^(\+6[14]) 0/, '$1')
    data.phone = data.phone.replace(/[- ]/g,'')
    if(isMobilePhone(data.phone, ["en-AU", "en-NZ"])) {
      createCandidate(data);
    } else {
      setError("phone", {message: "Invalid number"})
    }
  }

  function createCandidate(data: Inputs) {
    if(auth.user) {
      setRegisterLoading(true)
      const params = {
        body:{
          id: auth.user.username,
          data: data
        }
      };
      
      API.post("", "/candidates",params)
      .then(() => {
        API.get("", `/candidates/${auth.user.username}`, {})
        .then((response: Candidate) => {
          setRegisterLoading(false)
          if(response) {
            localStorage.setItem("lowie-welcome-dialog", "true")
            auth.setCandidate(response)
          }
        })
        .catch((error: any) => {
          console.log("Error: fetching candidate by id", error)
          setRegisterLoading(false)
        })
      })
      .catch((error: any) => {
          console.log("ERROR adding candidate", error);
          setRegisterLoading(false)
      })  
    }
  }

  const handleDrop = (files: Array<File>) => {
    if(auth.user) {
      var objKey = auth.user?.username + "_CV_" + files[0].name;
        
      setLoading(true)
      Storage.put(objKey, files[0])
      .then(() => {
          Storage.get(objKey, { level: 'public', download:false })
              .then(() => { 
                  console.log("FILE UPLOAD", objKey)
                  setUploadedCvFile(files)
                  setValue("cvFile", objKey)
                  clearErrors("cvFile")
                  setLoading(false)
              })
              .catch((error: any) => { 
                console.log("ERROR fetching from s3", error);
                setLoading(false)
              });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
        setLoading(false)
      });
    }
  }

  const cvFileDropzoneClass = () => {
      if(errors.cvFile) {
          return classes.dropzoneError;
      } else {
          return classes.dropzone
      }
  }

  function calcSize(fileSize: number): string {
    if (fileSize < 1000000) {
      return `${Math.round(fileSize/Math.pow(10,3))} KB`
    }
    return `${(fileSize/Math.pow(10,6)).toFixed(1)} MB`
  }

  return (
    <div style={{position: "relative", padding: 12}}> 
          <div>
            {!auth.isCandidateSignedIn && 
              <div className={classes.registerPage}>

                <div style={{maxWidth: 700}}>
                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 32
                  }}>
                  <img
                      src="images/lowie-logo-1.png"
                      alt="Lowie Recruitment"
                      width={300}
                  />
                </div>


                <Card style={{marginBottom: 32}}>
                  <div style={{padding: 16}}>

                  <div style={{marginBottom: 32, display: "flex", justifyContent: "center"}}>
                      <h3>Create a new account</h3>
                  </div>

                  <div style={{marginBottom: 32}}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <FormControl
                        error={Boolean(errors.title)} 
                        className={classes.input}
                        size="small">
              
                        <FormLabel className={classes.label}>Title</FormLabel>
              
                        <Controller
                          as={
                            <Select displayEmpty={true} variant="filled">
                              <MenuItem value="Mr">Mr</MenuItem>
                              <MenuItem value="Miss">Miss</MenuItem>
                              <MenuItem value="Ms">Ms</MenuItem>
                              <MenuItem value="Mrs">Mrs</MenuItem>
                            </Select>
                          }
                          name="title"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                        <FormHelperText>
                          {errors.title && errors.title.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormControl 
                        className={classes.input}
                        error={Boolean(errors.firstName)}>
              
                        <FormLabel className={classes.label}>First Name</FormLabel>
              
                        <Controller
                          as={
                            <TextField
                              placeholder="Enter first name"
                              fullWidth
                              variant="filled"
                              size="small"
                              error={errors.firstName ? true : false}
                              helperText={errors.firstName?.message}
                              FormHelperTextProps={{className: classes.error}}/>
                          }
                          name="firstName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <FormControl 
                        className={classes.input}
                        error={Boolean(errors.surname)}>
              
                        <FormLabel className={classes.label}>Surname</FormLabel>
              
                        <Controller
                          as={
                            <TextField
                              placeholder="Enter surname name"
                              fullWidth
                              variant="filled"
                              size="small"
                              error={errors.surname ? true : false}
                              helperText={errors.surname?.message}
                              FormHelperTextProps={{className: classes.error}}/>
                          }
                          name="surname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl 
                        className={classes.input}
                        error={Boolean(errors.email)}>
              
                        <FormLabel className={classes.label}>Email</FormLabel>
              
                        <Controller
                          as={
                            <TextField
                              placeholder="Enter email"
                              fullWidth
                              variant="filled"
                              size="small"
                              error={errors.email ? true : false}
                              helperText={errors.email?.message}
                              FormHelperTextProps={{className: classes.error}}/>
                          }
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl 
                        className={classes.input}
                        error={Boolean(errors.phone)}>
              
                        <FormLabel className={classes.label}>Mobile number</FormLabel>
              
                        <Controller
                          render={({onChange, value}) => (
                            <MuiPhoneNumber
                              defaultCountry={'nz'}
                              onlyCountries={['nz', 'au']}
                              placeholder="Enter mobile number"
                              fullWidth
                              variant="filled"
                              size="small"
                              countryCodeEditable={false}
                              value={value}
                              onChange={v => {
                                onChange(v)
                                clearErrors("phone")
                              }}
                              error={errors.phone ? true : false}
                              helperText={errors.phone?.message}
                              FormHelperTextProps={{className: classes.error}}/>
                          )}
                          name="phone"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        error={Boolean(errors.location)} 
                        className={classes.input}
                        size="small">
              
                        <FormLabel className={classes.label}>Preferred Work Location</FormLabel>
              
                        <Controller
                          as={
                            <Select displayEmpty={true} variant="filled">
                              <MenuItem value="">Select a location</MenuItem>
                              <MenuItem value="Any">Any</MenuItem>
                              <ListSubheader>Northland</ListSubheader>
                              <MenuItem value="Far North">Far North</MenuItem>
                              <MenuItem value="Kaipara">Kaipara</MenuItem>
                              <MenuItem value="Whangerei">Whangerei</MenuItem>

                              <ListSubheader>Auckland</ListSubheader>
                              <MenuItem value="Auckland City">Auckland City</MenuItem>
                              <MenuItem value="Franklin">Franklin</MenuItem>
                              <MenuItem value="Hauraki Gulf Island">Hauraki Gulf Island</MenuItem>
                              <MenuItem value="Manukau City">Manukau City</MenuItem>
                              <MenuItem value="North Shore City">North Shore City</MenuItem>
                              <MenuItem value="Papakura">Papakura</MenuItem>
                              <MenuItem value="Rodney">Rodney</MenuItem>
                              <MenuItem value="Waiheke Island">Waiheke Island</MenuItem>
                              <MenuItem value="Waitakere City">Waitakere City</MenuItem>

                              <ListSubheader>Waikato</ListSubheader>
                              <MenuItem value="Hamilton">Hamilton</MenuItem>
                              <MenuItem value="Hauraki">Hauraki</MenuItem>
                              <MenuItem value="Matamata-Piako">Matamata-Piako</MenuItem>
                              <MenuItem value="Otorohonga">Otorohonga</MenuItem>
                              <MenuItem value="South Waikato">South Waikato</MenuItem>
                              <MenuItem value="Taupo">Taupo</MenuItem>
                              <MenuItem value="Thames-Coromandel">Thames-Coromandel</MenuItem>
                              <MenuItem value="Waikato">Waikato</MenuItem>
                              <MenuItem value="Waipa">Waipa</MenuItem>
                              <MenuItem value="Waitomo">Waitomo</MenuItem>

                              <ListSubheader>Bay of plenty</ListSubheader>
                              <MenuItem value="Kawerau">Kawerau</MenuItem>
                              <MenuItem value="Opotiki">Opotiki</MenuItem>
                              <MenuItem value="Rotorua">Rotorua</MenuItem>
                              <MenuItem value="Tauranga">Tauranga</MenuItem>
                              <MenuItem value="Wester bay of plenty">Wester bay of plenty</MenuItem>
                              <MenuItem value="Whakatane">Whakatane</MenuItem>

                              <ListSubheader>Gisborne</ListSubheader>
                              <MenuItem value="Gisborne">Gisborne</MenuItem>

                              <ListSubheader>Hawkes Bay</ListSubheader>
                              <MenuItem value="Central Hawkes Bay">Central Hawkes Bay</MenuItem>
                              <MenuItem value="Hastings">Hastings</MenuItem>
                              <MenuItem value="Napier">Napier</MenuItem>
                              <MenuItem value="Wairoa">Wairoa</MenuItem>

                              <ListSubheader>Taranaki</ListSubheader>
                              <MenuItem value="New Plymouth">New Plymouth</MenuItem>
                              <MenuItem value="South Taranaki">South Taranaki</MenuItem>
                              <MenuItem value="Stratford">Stratford</MenuItem>

                              <ListSubheader>Manawatu</ListSubheader>
                              <MenuItem value="Horowhenua">Horowhenua</MenuItem>
                              <MenuItem value="Manawatu">Manawatu</MenuItem>
                              <MenuItem value="Palmerston North">Palmerston North</MenuItem>
                              <MenuItem value="Rangitikei">Rangitikei</MenuItem>
                              <MenuItem value="Ruapehu">Ruapehu</MenuItem>
                              <MenuItem value="Tararua">Tararua</MenuItem>
                              <MenuItem value="Whanganui">Whanganui</MenuItem>

                              <ListSubheader>Wellington</ListSubheader>
                              <MenuItem value="Carterton">Carterton</MenuItem>
                              <MenuItem value="Kapiti Coast">Kapiti Coast</MenuItem>
                              <MenuItem value="Lower Hutt">Lower Hutt</MenuItem>
                              <MenuItem value="Masterton">Masterton</MenuItem>
                              <MenuItem value="Porirua">Porirua</MenuItem>
                              <MenuItem value="South Wairarapa">South Wairarapa</MenuItem>
                              <MenuItem value="Upper Hutt">Upper Hutt</MenuItem>
                              <MenuItem value="Wellington">Wellington</MenuItem>

                              <ListSubheader>Nelson / Tasman</ListSubheader>
                              <MenuItem value="Nelson">Nelson</MenuItem>
                              <MenuItem value="Tasman">Tasman</MenuItem>

                              <ListSubheader>Marlborough</ListSubheader>
                              <MenuItem value="Blenheim">Blenheim</MenuItem>
                              <MenuItem value="Kaikoura">Kaikoura</MenuItem>
                              <MenuItem value="Marlborough">Marlborough</MenuItem>

                              <ListSubheader>West Coast</ListSubheader>
                              <MenuItem value="Buller">Buller</MenuItem>
                              <MenuItem value="Grey">Grey</MenuItem>
                              <MenuItem value="Westland">Westland</MenuItem>

                              <ListSubheader>Canterbury</ListSubheader>
                              <MenuItem value="Ashburton">Ashburton</MenuItem>
                              <MenuItem value="Banks Peninsula">Banks Peninsula</MenuItem>
                              <MenuItem value="Christchurch City">Christchurch City</MenuItem>
                              <MenuItem value="Hurunui">Hurunui</MenuItem>
                              <MenuItem value="Mackenzie">Mackenzie</MenuItem>
                              <MenuItem value="Selwyn">Selwyn</MenuItem>
                              <MenuItem value="Timaru">Timaru</MenuItem>
                              <MenuItem value="Waimakariri">Waimakariri</MenuItem>
                              <MenuItem value="Waimate">Waimate</MenuItem>

                              <ListSubheader>Otago</ListSubheader>
                              <MenuItem value="Central Otago">Central Otago</MenuItem>
                              <MenuItem value="Clutha">Clutha</MenuItem>
                              <MenuItem value="Dunedin">Dunedin</MenuItem>
                              <MenuItem value="Queenstown-Lakes">Queenstown-Lakes</MenuItem>
                              <MenuItem value="South Otago">South Otago</MenuItem>
                              <MenuItem value="Waitaki">Waitaki</MenuItem>
                              <MenuItem value="Wanaka">Wanaka</MenuItem>

                              <ListSubheader>Southland</ListSubheader>
                              <MenuItem value="Catlins">Catlins</MenuItem>
                              <MenuItem value="Gore">Gore</MenuItem>
                              <MenuItem value="Invercargill">Invercargill</MenuItem>
                              <MenuItem value="Southland">Southland</MenuItem>

                            </Select>
                          }
                          name="location"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                        <FormHelperText>
                          {errors.location && errors.location.message}
                        </FormHelperText>
              
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        error={Boolean(errors.industry)} 
                        className={classes.input}
                        size="small">
              
                        <FormLabel className={classes.label}>Select an Industry</FormLabel>
              
                        <Controller
                          as={
                            <Select displayEmpty={true} variant="filled">
                              <MenuItem value="">Select an industry</MenuItem>
                              <MenuItem value="Information Technology">Information Technology</MenuItem>
                              <MenuItem value="Human Resources & Recruitment">Human Resources & Recruitment</MenuItem>
                              <MenuItem value="Account and Finance">Accounting and Finance</MenuItem>
                              <MenuItem value="Engineering">Engineering</MenuItem>
                              <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                              <MenuItem value="Office Support">Office Support</MenuItem>
                              <MenuItem value="Procurement and supplychain">Procurement and supplychain</MenuItem>
                              <MenuItem value="Property and construction">Property and construction</MenuItem>
                              <MenuItem value="Retail">Retail</MenuItem>
                              <MenuItem value="Sales">Sales</MenuItem>
                              <MenuItem value="Transport and Logistics">Transport and Logistics</MenuItem>
                              <MenuItem value="Call centre and customer service">Call centre and customer service</MenuItem>
                              <MenuItem value="Education and training">Education and training</MenuItem>
                              <MenuItem value="Hospitality, Tourism and Travel">Hospitality, Tourism and Travel</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </Select>
                          }
                          name="industry"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                        <FormHelperText>
                          {errors.industry && errors.industry.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    
                    <Grid item xs={12} md={6}>
                      {watchIndustry === "Other" && 
                        <FormControl 
                          className={classes.input}
                          error={Boolean(errors.industryOther)}>
              
                          <FormLabel className={classes.label}>Enter other</FormLabel>
              
                          <Controller
                            as={
                              <TextField
                                placeholder="Enter other"
                                fullWidth
                                variant="filled"
                                size="small"
                                error={errors.industryOther ? true : false}
                                helperText={errors.industryOther?.message}
                                FormHelperTextProps={{className: classes.error}}
                                />
                            }
                            name="industryOther"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required"
                            }}
                          />
                        </FormControl>
                      }
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        error={Boolean(errors.uploadCv)} 
                        className={classes.input}
                        size="small">
              
                        <FormLabel className={classes.label}>Do you want to upload a CV?</FormLabel>
              
                        <Controller
                          render={({ onChange, value}) => (
                            <Select 
                              displayEmpty
                              variant="filled"
                              onChange={(e) => {
                                let val = e.target.value as string
                                onChange(val)
                                clearErrors("cvFile")
                              }}
                              value={value}>
                              <MenuItem value="">Choose an option</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No, Later">No, Later</MenuItem>
                            </Select>
                          )}
                          name="uploadCv"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Required"
                          }}
                        />
                        <FormHelperText>
                          {errors.uploadCv && errors.uploadCv.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    
                    {watch("uploadCv") === "Yes" &&
                      <Grid item xs={12}>

                        <FormControl
                          error={Boolean(errors.cvFile)} 
                          className={classes.input}>
                
                          <FormLabel className={classes.label}>Upload CV</FormLabel>
                          <Controller
                            control={control}
                            name="cvFile"
                            defaultValue=""
                            rules={{
                                required: true
                            }}
                            render={() => (
                                <div>
                                  <Dropzone onDrop={handleDrop} multiple={false}
                                    accept={[
                                      'image/jpeg',
                                      'image/png',
                                      'application/pdf',
                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                    {({ getRootProps, getInputProps }) => (
                                      <Paper
                                        variant="outlined"
                                        {...getRootProps({className: cvFileDropzoneClass()})}
                                      >
                                        <input {...getInputProps()} name="cvFile" />
                                        <p>Drag 'n' drop files here, or click to select files</p>
                                      </Paper>
                                    )}
                                  </Dropzone>
                                  {uploadedCvFile.length !== 0 && 
                                      <List>
                                      {uploadedCvFile.map((f: File, index: number) => (
                                        <ListItem key={index}>
                                          <ListItemIcon>
                                            <InsertDriveFile />
                                          </ListItemIcon>
                                          <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                        </ListItem>
                                      ))}
                                    </List>
                                  }
                                </div>
                            )} />
                          <FormHelperText>
                              {errors.cvFile && "Required"}
                          </FormHelperText>
                          
                        </FormControl>
                      </Grid>
                    }

                  </Grid>
                </div>
                
                {registerLoading ? 
                      <Button style={{width: "100%", padding: 16}} variant="contained" color="primary">
                          <CircularProgress size={25} style={{color: "white"}}/>
                      </Button>
                  :
                    <Button style={{width: "100%", padding: 16}} variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={loading}>
                      <h5>Register</h5>
                    </Button>
                  }
                      </div>
                  </Card>
                </div>
              </div>
            } 
          </div>
    </div>
  );
}
