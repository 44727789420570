import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import RegistrationProcess from './RegistrationProcess';
import { Candidate } from '../../CandidateTypes';

interface Props {
    candidate: Candidate | undefined
}

export default function RegistrationRoute({ candidate }: Props) {

    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to="/portal"/>
            </Route>
            <Route path={`${path}/:step`}>
                <RegistrationProcess candidate={candidate} />
            </Route>
        </Switch>
    )
}
