import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Accordion, AccordionSummary, AccordionDetails, Grid, Select, MenuItem, 
  FormControl, FormLabel, FormHelperText,
  Paper, List, ListItem, ListItemText, ListItemIcon, Button, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form";
import { ExpandMore, Edit, Clear, InsertDriveFile, Save } from "@material-ui/icons"
import Dropzone from 'react-dropzone'
import { RightToWorkInfo } from "../../CandidateTypes";
import { API, Storage } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    input: {
        marginBottom: theme.spacing(3),
        width: "100%"
    },
    label: {
        marginBottom: 6
    },
    header: {
      display: "flex",
      marginBottom: 16
    },
    title: {
      flexGrow: 1
    },
    uploadCard: {
        marginTop: 8,
        marginBottom: 24
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    dropzone: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 97,
        paddingBottom: 97,
        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#bdbdbd",
        borderStyle: "dashed",
        backgroundColor: "#eeeeee",
        color: "#757575",
        outline: "none",
        transition: "border .24s ease-in-out"
    },
    error: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 97,
        paddingBottom: 97,
        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "red",
        borderStyle: "dashed",
        backgroundColor: "#eeeeee",
        color: "#757575",
        outline: "none",
        transition: "border .24s ease-in-out"
    },
    disabled: {
        opacity: 0.4
    },
    editButton: {
        width: 100,
        marginRight: 8
    },
    actionButton: {
        width: 100,
        marginBottom: 8,
        marginRight: 8
    }
  }),
);

interface Props {
    data?: RightToWorkInfo;
    candidate?: string
    refreshCandidateData: () => void
    expand: boolean;
    setExpanded: (expand: boolean) => void;
}

export default function RightToWorkCard({ data, candidate, refreshCandidateData, expand, setExpanded }: Props) {
    const classes = useStyles()

    const { handleSubmit, watch, control, errors, setValue, clearErrors, reset } = useForm<RightToWorkInfo>({
        shouldUnregister: false
    });

    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [info, setInfo] = useState<RightToWorkInfo>()

    const nzCitizen: string = watch("nzCitizen")
    const entitledToWork: string = watch("entitledToWork")
    const [uploadedPassport, setPassport] = useState<Array<File>>([])
    const [uploadedWorkVisa, setWorkVisa] = useState<Array<File>>([])
    const [uploadedBirthCert, setBirthCert] = useState<Array<File>>([])
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

    useEffect(() => {
        if(data) {
            setInfo(data)
            reset(data)
        }
    }, [data]);

    const onSave = (data: RightToWorkInfo) => {
        if(candidate) {
            if(data.entitledToWork === "No") {
                setShowErrorDialog(true)
            } else {
                setLoading(true)
                const params = {
                    body: {
                        data: data,
                        section: "rightToWork"
                    }
                };
                API.post("", `/candidates/${candidate}/profile/update`,params)
                .then(() => {
                    setReadOnly(true);
                    setLoading(false);
                    refreshCandidateData()
                })
                .catch((error: any) => {
                    console.log("ERROR updating candidate right to work info", error);
                    setLoading(false)
                    setError("Error: unable to update info")
                })  
            }
        }
    }

    const handlePassportDrop = (files: Array<File>) => {
        if(candidate) {
            var objKey = candidate + "_PASSPORT_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
            
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("passport", objKey)
                        clearErrors("passport")
                        setPassport(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    const handleWorkVisaDrop = (files: any) => {
        if(candidate) {
            var objKey = candidate + "_WORKVISA_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("workVisa", objKey)
                        clearErrors("workVisa")
                        setWorkVisa(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    const handleBirthCertDrop = (files: any) => {
        if(candidate) {
            var objKey = candidate + "_BIRTHCERT_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("birthCert", objKey)
                        clearErrors("birthCert")
                        setBirthCert(files)
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    function getPassportDropzoneClass() {
        if(readOnly) {
            return `${classes.disabled} ${classes.dropzone}`
        } else{
            if(errors.passport) {
                return classes.error;
            } else {
                return classes.dropzone
            }
        }
    } 

    function getWorkVisaDropzoneClass() {
        if(readOnly) {
            return `${classes.disabled} ${classes.dropzone}`
        } else{
            if(errors.workVisa) {
                return classes.error;
            } else {
                return classes.dropzone
            }
        }
    } 

    function getBirthCertDropzoneClass() {
        if(readOnly) {
            return `${classes.disabled} ${classes.dropzone}`
        } else{
            if(errors.birthCert) {
                return classes.error;
            } else {
                return classes.dropzone
            }
        }
    } 
    
    function calcSize(fileSize: number): string {
        if (fileSize < 1000000) {
            return `${Math.round(fileSize/Math.pow(10,3))} KB`
        }
        return `${(fileSize/Math.pow(10,6)).toFixed(1)} MB`
    }

    const getBirthCertFileName = (filename: string) => {
        var f = filename.split("BIRTHCERT_")
        return f[1];
    }

    const getPassportFileName = (filename: string) => {
        var f = filename.split("PASSPORT_")
        return f[1];
    }

    const getWorkVisaFileName = (filename: string) => {
        var f = filename.split("WORKVISA_")
        return f[1];
    }

    return (
        <div id="right-to-work-form">
            <Accordion style={{marginTop: 32}}
                expanded={expand}
                onChange={() => setExpanded(!expand)}> 
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <h4>Right To Work</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>

                    <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}>
                                <p style={{marginBottom: 24}}>Enter your right to work details.</p>
                            </div>
                            <div>
                                <Button variant="contained" color="primary" className={classes.editButton} onClick={() => setReadOnly(false)}>
                                    Edit
                                    <Edit style={{marginLeft: 4}} fontSize="small" />
                                </Button>
                            </div>
                        </div>
                        

                        <FormControl
                            error={Boolean(errors.nzCitizen)} 
                            className={classes.input}>

                            <FormLabel className={classes.label}> Are you a New Zealand citizen or permanent resident</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <Select variant="filled" 
                                        defaultValue=""
                                        displayEmpty={true}
                                        disabled={readOnly}
                                        value={value}
                                        onChange={event => {
                                            let val = event.target.value as string
                                            onChange(val)
                                            if(val === "Yes") {
                                                setValue("entitledToWork", "")
                                            }
                                            clearErrors(["birthCert", "passport", 'workVisa'])
                                        }}>
                                        <MenuItem value="">Select an option</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                )}
                                name="nzCitizen"
                                control={control}
                                defaultValue=""
                                rules={{
                                required: "This is required"
                                }}
                            />
                            <FormHelperText>
                                {errors.nzCitizen && errors.nzCitizen.message}
                            </FormHelperText>
                        </FormControl>

                        {nzCitizen === "No" && 
                            <div>
                                <FormControl
                                    error={Boolean(errors.entitledToWork)} 
                                    className={classes.input}>

                                    <FormLabel className={classes.label}> Are you legally entitled to work in New Zealand</FormLabel>

                                    <Controller
                                        as={
                                            <Select variant="filled" 
                                                defaultValue=""
                                                displayEmpty={true}
                                                disabled={readOnly}>
                                                <MenuItem value="">Select an option</MenuItem>
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        }
                                        name="entitledToWork"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                        required: "This is required"
                                        }}
                                    />
                                    <FormHelperText>
                                        {errors.entitledToWork && errors.entitledToWork.message}
                                    </FormHelperText>
                                </FormControl>                    
                            </div>
                        }
                        {(nzCitizen === "Yes" || entitledToWork === "Yes") &&
                            <div>
                                <h5 style={{marginBottom: 16}}>Documents</h5>
                                
                                {nzCitizen === "Yes" &&
                                    <FormControl
                                        error={Boolean(errors.documentType)} 
                                        className={classes.input}>

                                        <FormLabel className={classes.label}> Select document to upload</FormLabel>

                                        <Controller
                                            render={({ onChange, value }) => (
                                                <Select 
                                                    variant="filled" 
                                                    displayEmpty={true}
                                                    disabled={readOnly}
                                                    defaultValue=""
                                                    value={value}
                                                    onChange={event => {
                                                        let val = event.target.value as string
                                                        onChange(val)
                                                        clearErrors(["birthCert", "passport", 'workVisa'])
                                                    }}>
                                                    <MenuItem value="">Select an option</MenuItem>
                                                    <MenuItem value="Passport">Passport</MenuItem>
                                                    <MenuItem value="Birth Certificate">Birth Certificate</MenuItem>
                                                </Select>
                                            )}
                                            name="documentType"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                            required: "This is required"
                                            }}
                                        />
                                        <FormHelperText>
                                            {errors.documentType && errors.documentType.message}
                                        </FormHelperText>
                                    </FormControl>     
                                }     

                                {(watch("documentType") === "Passport" || entitledToWork === "Yes")  &&
                                    <>
                                        <h6 style={{marginTop: 8, marginBottom: 8}}>Passport</h6>
                                
                                        {info?.passport &&
                                            <Button variant="contained" color="primary" disabled={readOnly} onClick={() => {
                                                if(info) {
                                                    setInfo({
                                                        ...info,
                                                        passport: ""
                                                    })
                                                    setValue("passport", "")
                                                }
                                            }}>
                                                Upload new file
                                            </Button>
                                        }
                                        
                                        <Grid item className={classes.uploadCard}  sm={12} lg={6}>
                                            {info?.passport ?
                                                <div>
                                                    {(info?.passport.endsWith(".png") || info?.passport.endsWith(".jpeg") || info?.passport.endsWith(".jpg")) ?
                                                        <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.passport}`} />
                                                        :
                                                        <div style={{display: "flex"}}>
                                                            <InsertDriveFile />
                                                            <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.passport}`}>{getPassportFileName(info?.passport)}</a>
                                                        </div>
                                                    }
                                                </div>     
                                            : 
                                                <FormControl
                                                    error={Boolean(errors.passport)} 
                                                    className={classes.input}>

                                                    <FormLabel className={classes.label}>Upload Passport</FormLabel>
                                                    <Controller
                                                        control={control}
                                                        name="passport"
                                                        defaultValue=""
                                                        rules={{
                                                            required: entitledToWork === "Yes" ? "If you do not have this now, please press skip below and add later." : false 
                                                        }}
                                                        render={() => (
                                                        <div >
                                                            
                                                            <Dropzone onDrop={handlePassportDrop} multiple={false} disabled={readOnly}
                                                            accept={[
                                                                'image/jpeg',
                                                                'image/png',
                                                                'application/pdf',
                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <Paper
                                                                variant="outlined"
                                                                {...getRootProps({className: getPassportDropzoneClass()})}
                                                                >
                                                                <input {...getInputProps()} name="passport" />
                                                                <p>Drag 'n' drop files here, or click to select files</p>
                                                                </Paper>
                                                            )}
                                                            </Dropzone>
                                                            {uploadedPassport.length !== 0 && 
                                                                <List>
                                                                {uploadedPassport.map((f: File, index: number) => (
                                                                <ListItem key={index}>
                                                                    <ListItemIcon>
                                                                    <InsertDriveFile />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                                </ListItem>
                                                                ))}
                                                            </List>
                                                            }
                                                        </div>
                                                        )}
                                                    />
                                                    <FormHelperText>
                                                        {errors.passport && "If you do not have this now, please press skip below and add later."}
                                                    </FormHelperText>
                                                </FormControl>
                                            }
                                        </Grid>
                                    </>
                                }                                
                            </div>
                        }

                        {(nzCitizen === "Yes" && watch("documentType") === "Birth Certificate") &&
                            <div>

                            <h6 style={{marginTop: 8, marginBottom: 8}}>Birth Certificate</h6>

                                {info?.birthCert &&

                                    <Button variant="contained" color="primary" disabled={readOnly} onClick={() => {
                                        if(info) {
                                            setInfo({
                                                ...info,
                                                birthCert: ""
                                            })
                                            setValue("birthCert", "")
                                        }
                                    }}>
                                        Upload new file
                                    </Button>
                                }

                                <Grid item className={classes.uploadCard}  sm={12} lg={6}>
                                    {info?.birthCert ?
                                        <div>
                                            {(info?.birthCert.endsWith(".png") || info?.birthCert.endsWith(".jpeg") || info?.birthCert.endsWith(".jpg")) ?
                                                <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.birthCert}`} />
                                                :
                                                <div style={{display: "flex"}}>
                                                    <InsertDriveFile />
                                                    <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.birthCert}`}>{getBirthCertFileName(info?.birthCert)}</a>
                                                </div>
                                            }
                                        </div>                                    
                                    : 
                                        <FormControl
                                            error={Boolean(errors.birthCert)} 
                                            className={classes.input}>

                                            <FormLabel className={classes.label}>Upload Birth Certificate</FormLabel>
                                            <Controller
                                                control={control}
                                                name="birthCert"
                                                defaultValue=""
                                                rules={{
                                                    required: "This is required"
                                                }}
                                                render={() => (
                                                <div >
                                                    
                                                    <Dropzone onDrop={handleBirthCertDrop} multiple={false} disabled={readOnly}
                                                    accept={[
                                                        'image/jpeg',
                                                        'image/png',
                                                        'application/pdf',
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <Paper
                                                        variant="outlined"
                                                        {...getRootProps({className: getBirthCertDropzoneClass()})}
                                                        >
                                                        <input {...getInputProps()} name="birthCert" />
                                                        <p>Drag 'n' drop files here, or click to select files</p>
                                                        </Paper>
                                                    )}
                                                    </Dropzone>
                                                    {uploadedBirthCert.length !== 0 && 
                                                        <List>
                                                        {uploadedBirthCert.map((f: File, index: number) => (
                                                        <ListItem key={index}>
                                                            <ListItemIcon>
                                                            <InsertDriveFile />
                                                            </ListItemIcon>
                                                            <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                        </ListItem>
                                                        ))}
                                                    </List>
                                                    }
                                                </div>
                                                )}
                                            />
                                            <FormHelperText>
                                                {errors.birthCert && "This is required"}
                                            </FormHelperText>
                                        </FormControl>
                                    }
                                </Grid>
                            </div>
                        }

                        {entitledToWork === "Yes" &&
                            <div>

                            <h6 style={{marginTop: 8, marginBottom: 8}}>Work Visa</h6>

                                {info?.workVisa &&
                                    <Button variant="contained" color="primary" disabled={readOnly} onClick={() => {
                                        if(info) {
                                            setInfo({
                                                ...info,
                                                workVisa: ""
                                            })
                                            setValue("workVisa", "")
                                        }
                                    }}>
                                        Upload new file
                                    </Button>
                                }

                                <Grid item className={classes.uploadCard}  sm={12} lg={6}>
                                    {info?.workVisa ?
                                       <div>
                                            {(info?.workVisa.endsWith(".png") || info?.workVisa.endsWith(".jpeg") || info?.workVisa.endsWith(".jpg")) ?
                                                <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.workVisa}`} />
                                                :
                                                <div style={{display: "flex"}}>
                                                    <InsertDriveFile />
                                                    <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.workVisa}`}>{getWorkVisaFileName(info?.workVisa)}</a>
                                                </div>
                                            }
                                        </div>       
                                    : 
                                        <FormControl
                                            error={Boolean(errors.workVisa)} 
                                            className={classes.input}>

                                            <FormLabel className={classes.label}>Upload Work Visa</FormLabel>
                                            <Controller
                                                control={control}
                                                name="workVisa"
                                                defaultValue=""
                                                rules={{
                                                    required: "This is required"
                                                }}
                                                render={() => (
                                                <div >
                                                    
                                                    <Dropzone onDrop={handleWorkVisaDrop} multiple={false} disabled={readOnly}
                                                    accept={[
                                                        'image/jpeg',
                                                        'image/png',
                                                        'application/pdf',
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <Paper
                                                        variant="outlined"
                                                        {...getRootProps({className: getWorkVisaDropzoneClass()})}
                                                        >
                                                        <input {...getInputProps()} name="workVisa" />
                                                        <p>Drag 'n' drop files here, or click to select files</p>
                                                        </Paper>
                                                    )}
                                                    </Dropzone>
                                                    {uploadedWorkVisa.length !== 0 && 
                                                        <List>
                                                        {uploadedWorkVisa.map((f: File, index: number) => (
                                                        <ListItem key={index}>
                                                            <ListItemIcon>
                                                            <InsertDriveFile />
                                                            </ListItemIcon>
                                                            <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                        </ListItem>
                                                        ))}
                                                    </List>
                                                    }
                                                </div>
                                                )}
                                            />
                                            <FormHelperText>
                                                {errors.workVisa && "This is required"}
                                            </FormHelperText>
                                        </FormControl>
                                    }
                                </Grid>
                            </div>
                        }
                        
                        <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}/>
                                {!readOnly &&
                                    <div>
                                        {error &&
                                            <span style={{color: "red", marginRight: 8}}>{error}</span>
                                        }
                                        <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => {
                                            setReadOnly(true)
                                            refreshCandidateData()
                                            clearErrors()
                                        }}>
                                            Cancel
                                            <Clear style={{marginLeft: 4}} fontSize="small" />
                                        </Button>
                                        {loading ?
                                            <Button variant="contained" color="primary" className={classes.actionButton}>
                                                <CircularProgress size={25} style={{color: "white"}} />
                                            </Button>
                                        :
                                            <Button onClick={handleSubmit(onSave)} variant="contained" color="primary" className={classes.actionButton}>
                                                Save
                                                <Save style={{marginLeft: 4}} fontSize="small" />
                                            </Button>
                                        }
                                    </div>
                                }
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Dialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We are unable to proceed with your application, please contact Lowie for details.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowErrorDialog(false)} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
