import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router'
import Menu from "./AppMenu";
import { MyAuth } from '../hooks/useAuth';

interface Props {
    children: React.ReactNode
    auth: MyAuth
    path: string
}

export default function PrivateRoute({ children, auth, ...rest}: Props) {

    useEffect(() => {
        if(window) {
            window.scrollTo(0, 0)
        }
    }, [window])

    return (
        <Route
            {...rest}
            render={() => (
                (auth.isSignedIn && auth.isCandidateSignedIn) ?
                    <Menu candidate={auth.candidate} auth={auth}>
                        {children}
                    </Menu>
                :
                    <Redirect to={{ pathname: "/" }} />
            )}
        />
    )
}
