import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Select, MenuItem, TextField, Button, FormControl, FormLabel, FormHelperText
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form";
import { API } from "aws-amplify";
import { AdditionalInfoPart2, Candidate } from '../../CandidateTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    input: {
        marginBottom: theme.spacing(3),
        width: "100%"
    },
    label: {
      marginBottom: 6
    },
    header: {
      display: "flex",
      marginBottom: 16
    },
    title: {
      flexGrow: 1
    },
    uploadCard: {
        marginBottom: 40
    },
    section: {
      marginBottom: 16
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props {
  activeStep: number;
  incrementActiveStep: () => void
  candidate?: Candidate
}

const AdditionalInformationPart2 = ({ candidate, activeStep, incrementActiveStep } : Props) => {
  const classes = useStyles()

  const { handleSubmit, watch, control, errors, reset } = useForm<AdditionalInfoPart2>();

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let jsonForm = localStorage.getItem('additionalInfoPart2Form')
    if(jsonForm) {
        let form: AdditionalInfoPart2 = JSON.parse(jsonForm)
        reset(form)
    }
  }, [])

  const handleNext = (data: AdditionalInfoPart2) => {
    setLoading(true)

    localStorage.setItem('additionalInfoPart2Form', JSON.stringify(data))

    const params = {
      body:{
        data: data,
        step: activeStep
      }
    };

    API.post("", `/candidates/${candidate?.username}/registration`,params)
    .then(() => {
      setLoading(false);
      incrementActiveStep();
    })
    .catch((error: any) => {
        console.log("ERROR", error);
        setLoading(false);
    })
  };
 
  return (
    <div>
      <div className={classes.header}>
          <h2>Health and Safety continued</h2>
      </div>

      <p style={{marginBottom: 24}}>Complete health and safety details.</p>

      <section className={classes.section}>
        <h3 style={{marginBottom: 16}}>Other details</h3>

        <FormControl
          error={Boolean(errors.drugAlcoholTests)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Would you be able to undertake work that requires regular drug and alcohol tests on site?</FormLabel>

          <Controller
              as={
                  <Select variant="filled" 
                      defaultValue=""
                      displayEmpty={true}>
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                  </Select>
              }
              name="drugAlcoholTests"
              control={control}
              defaultValue=""
              rules={{
              required: "This is required"
              }}
          />
          <FormHelperText>
              {errors.drugAlcoholTests && errors.drugAlcoholTests.message}
          </FormHelperText>
        </FormControl>

        {/* <FormControl
          error={Boolean(errors.transport)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Do you have reliable transport?</FormLabel>

          <Controller
              as={
                  <Select variant="filled" 
                      defaultValue=""
                      displayEmpty={true}>
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                  </Select>
              }
              name="transport"
              control={control}
              defaultValue=""
              rules={{
              required: "This is required"
              }}
          />
          <FormHelperText>
              {errors.transport && errors.transport.message}
          </FormHelperText>
        </FormControl> */}

        <FormControl
          error={Boolean(errors.claimedCompensation)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Have you claimed accident compensation in the last 12 months?</FormLabel>

          <Controller
              as={
                  <Select variant="filled" 
                      defaultValue=""
                      displayEmpty={true}>
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                  </Select>
              }
              name="claimedCompensation"
              control={control}
              defaultValue=""
              rules={{
              required: "This is required"
              }}
          />
          <FormHelperText>
              {errors.claimedCompensation && errors.claimedCompensation.message}
          </FormHelperText>
        </FormControl>
        
        {/* <FormControl
          error={Boolean(errors.criminalConvictions)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Do you have any past / pending criminal convictions?</FormLabel>

          <Controller
              as={
                  <Select variant="filled" 
                      defaultValue=""
                      displayEmpty={true}>
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                  </Select>
              }
              name="criminalConvictions"
              control={control}
              defaultValue=""
              rules={{
              required: "This is required"
              }}
          />
          <FormHelperText>
              {errors.criminalConvictions && errors.criminalConvictions.message}
          </FormHelperText>
        </FormControl>

        {watch("criminalConvictions") === "Yes" &&
          <FormControl
            error={Boolean(errors.criminalConvictionDetail)} 
            className={classes.input}>

            <FormLabel className={classes.label}>Please state what the conviction(s) is/are:</FormLabel>

            <Controller
                as={
                    <TextField
                        placeholder="Enter detail"
                        fullWidth
                        variant="filled"
                        error={errors.criminalConvictionDetail ? true : false}
                        helperText={errors.criminalConvictionDetail?.message}/>
                }
                name="criminalConvictionDetail"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required"
                }}
            />
          </FormControl>
        } */}
      </section>

        <div>
            <Button variant="contained" color="primary" onClick={handleSubmit(handleNext)} disabled={isLoading}>
                Next
            </Button>
        </div>
    </div>
  );
};
export default AdditionalInformationPart2;
