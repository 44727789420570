import React, { useState } from "react";
import {
  TextField, Select, MenuItem, Radio, RadioGroup,
  FormControlLabel, FormControl, FormLabel, Grid,
  Button, FormHelperText
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useForm, Controller } from "react-hook-form";
import { Candidate } from '../../CandidateTypes';
import { API } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      }
    },
    submit: {
      marginTop: 32,
      width: 100
    },
    select: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
  }),
);

interface Props {
  incrementActiveStep: () => void;
  candidate: Candidate | undefined;
  activeStep: number;
}

interface IrdForm {
  firstName: string;
  surname: string;
  irdNumber: string;
  taxCode: string;
  entitledToWork: string;
  signature: string;
  date: string;
}

const Ird = ({ incrementActiveStep, candidate, activeStep }: Props) => {
  const classes = useStyles()

  const { handleSubmit, control, errors } = useForm<IrdForm>();
  const [isLoading, setLoading] = useState<boolean>(false)

  const onSubmit = (data: IrdForm) => {
    if(candidate) {
      setLoading(true);

        const params = {
            body:{
              data: data,
              step: activeStep,
              id: candidate.username
            }
        };
        API.post("", `/candidates/jobPlacement/update`,params)
        .then(() => {
            incrementActiveStep();
        })
        .catch((error: any) => {
            console.log("ERROR updating ird form for candidate", error);
        })
       
      setLoading(false);
    }
  }

  return (
    <div>

      <h3 style={{marginBottom: 16}}>IRD Form</h3>

      <h4 style={{marginBottom: 16}}>1. Your Details</h4>

      <FormControl
        error={Boolean(errors.firstName)} 
        className={classes.input}>

        <FormLabel className={classes.label}>First Name</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter first name"
                    fullWidth
                    variant="filled"
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName?.message}/>
            }
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
        error={Boolean(errors.surname)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Surname</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter surname"
                    fullWidth
                    variant="filled"
                    error={errors.surname ? true : false}
                    helperText={errors.surname?.message}/>
            }
            name="surname"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
        error={Boolean(errors.irdNumber)} 
        className={classes.input}>

        <FormLabel className={classes.label}>IRD Number</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter IRD number"
                    fullWidth
                    variant="filled"
                    error={errors.irdNumber ? true : false}
                    helperText={errors.irdNumber?.message || "8 digit number"}/>
            }
            name="irdNumber"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <h4 style={{marginBottom: 16}}>2. Your Tax Code</h4>

      <p style={{marginBottom: 16}}>
        You must complete a separate Tax code declaration (IR330) for each
        source of income Choose only ONE tax code Refer to the flowchart on
        page 2 and then enter a tax code here..
      </p>

      <p>
      If you're a casual agricultural worker, shearer, shearing shedhand, recognised seasonal worker, election day worker or have
      a special tax code refer to Other tax code options at the bottom of page 2, choose your tax code and enter it in the tax code circle.

      </p>

      <FormControl
        error={Boolean(errors.taxCode)}
        className={classes.input}>

        <FormLabel className={classes.label}></FormLabel>

        <Controller
            as={
                <Select variant="filled" 
                    defaultValue=""
                    displayEmpty>
                    <MenuItem value="">Select applicable tax code</MenuItem>
                    <MenuItem value="M SL">M SL</MenuItem>
                    <MenuItem value="M">M</MenuItem> 
                    <MenuItem value="ME SL">ME SL</MenuItem>
                    <MenuItem value="ME">ME</MenuItem>
                    <MenuItem value="SB SL">SB SL</MenuItem>
                    <MenuItem value="SB">SB</MenuItem>
                    <MenuItem value="S SL">S SL</MenuItem>
                    <MenuItem value="S">S</MenuItem>
                    <MenuItem value="SH SL">SH SL</MenuItem>
                    <MenuItem value="SH">SH</MenuItem>
                    <MenuItem value="ST SL">ST SL</MenuItem>
                    <MenuItem value="ST">ST</MenuItem>
                    <MenuItem value="CAE">CAE</MenuItem>
                    <MenuItem value="EDW">EDW</MenuItem>
                    <MenuItem value="NSW">NSW</MenuItem>
                    <MenuItem value="STC">STC</MenuItem>
                </Select>
            }
            name="taxCode"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
          }}
        />

        <FormHelperText>
            {errors.taxCode && errors.taxCode.message}
        </FormHelperText>
      </FormControl>

      <h4 style={{marginBottom: 16}}>3. Your entitlement to work</h4>

      <FormControl
        error={Boolean(errors.entitledToWork)} 
        className={classes.input}>

        <FormLabel className={classes.label}>
          I am entitled under the Immigration Act 2009 to do the work that this tax code declaration relates to (tick the box that applies to you).</FormLabel>

        <Controller
            as={
                <RadioGroup>
                  <FormControlLabel value="I am a New Zealand or Australian citizen or am entitled to work indefinitely in New Zealand." control={<Radio />} label="I am a New Zealand or Australian citizen or am entitled to work indefinitely in New Zealand." />
                  <FormControlLabel value="I hold a valid visa with conditions allowing work in New Zealand." control={<Radio />} label="I hold a valid visa with conditions allowing work in New Zealand." />
                </RadioGroup>
            }
            name="entitledToWork"
            control={control}
            defaultValue=""
            rules={{
                required: "This is required"
            }}  
        />
        <FormHelperText>
            {errors.entitledToWork && errors.entitledToWork.message}
        </FormHelperText>
      </FormControl>

      <h4 style={{marginTop: 16, marginBottom: 16}}>4. Decleration</h4>

      <FormControl
        error={Boolean(errors.signature)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Signature</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter signature"
                    fullWidth
                    variant="filled"
                    error={errors.signature ? true : false}
                    helperText={errors.signature?.message}/>
            }
            name="signature"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
          error={Boolean(errors.date)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Date</FormLabel>

          <Controller
              render={({onChange, value}) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container>
                          <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker4"
                              value={value}
                              onChange={date => onChange(date)} />
                      </Grid>
                  </MuiPickersUtilsProvider>
              )}
              name="date"
              control={control}
              defaultValue={null}
              rules={{
                  required: "This is required"
              }}
          />
          <FormHelperText>
              {errors.date && errors.date.message}
          </FormHelperText>
      </FormControl>

      <div>
          <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
              Next
          </Button>
      </div>
    </div>
  );
};
export default Ird;
