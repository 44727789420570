import React from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Button, Dialog,  DialogActions, DialogContent
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applyButton: {
        marginLeft: 8,
      },
      actions: {
        '&.MuiCardActions-spacing > :not(:first-child)': {
          marginLeft: 0
        },
        display: "flex", 
        [theme.breakpoints.up('sm')]: {
            padding: 16
        },
        [theme.breakpoints.down('xs')]: {
            padding: 8
        }
      }
  }),
);

interface WelcomeDialogProps {
    open: boolean
    handleDialog: (open: boolean) => void
}

export default function WelcomeDialog({ open, handleDialog }: WelcomeDialogProps) {

    const classes = useStyles()

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogContent>
                <div style={{padding: 16}}>
                    <div style={{display: "flex"}}>
                        <CheckCircle style={{color: "#4caf50", marginRight: 8}} fontSize="large" />
                        <h3>Registration Successful</h3>
                    </div>
                    
                    <p style={{marginTop: 16}}>Thank you for registering with Lowie.</p>
                    
                </div>
                <DialogActions className={classes.actions}>
                    <div style={{flexGrow: 1}}></div>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => {
                            handleDialog(false)
                        }}>
                            Continue
                        </Button>

                        <Button variant="contained" color="primary" className={classes.applyButton} href="#/portal/jobs" onClick={() => {
                            handleDialog(false)
                        }}>
                            Apply for Jobs
                        </Button>
                    </div>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
