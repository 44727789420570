import React, { useEffect } from "react";
import "./App.css";
import {
  Container, createMuiTheme, MuiThemeProvider,
} from "@material-ui/core";
import {
  HashRouter,
  Switch
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from './components/PublicRoute';
import useAuth from "./hooks/useAuth";
import { MyAuth } from "./hooks/useAuth";
import Login from "./routes/authentication/Login";
import Portal from "./Portal";
import SignUp from './routes/authentication/SignUp';
import ForgotPassword from './routes/authentication/ForgotPassword';
import NewPassword from './routes/authentication/NewPassword';
import VerifyUser from './routes/authentication/VerifyUser';
import ProtectedRoute from './components/ProtectedRoute';
import Apply from './routes/Apply';
import { Hub } from "aws-amplify";

function App() {

  const auth: MyAuth = useAuth();

  const myTheme = createMuiTheme({
    palette: {
      primary: {
        light: '#5fcc76',
        main: '#259a49',
        dark: '#006b1e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#f73378',
        main: '#f50057',
        dark: '#ab003c',
        contrastText: '#fff',
      },
    },
  });

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          const originalUrl = decodeURIComponent(data);
          localStorage.setItem("lowie-route-from", originalUrl)
          break;
      }
    });
  }, []);

  return (
    <MuiThemeProvider theme={myTheme}>
      <HashRouter>
        <Container maxWidth="xl">
          <Switch>
            <PublicRoute path="/register">
              <SignUp auth={auth} />
            </PublicRoute>
            <PublicRoute path="/forgot-password">
              <ForgotPassword auth={auth} />
            </PublicRoute>
            <PublicRoute path="/new-password">
              <NewPassword auth={auth} />
            </PublicRoute>
            <PublicRoute path="/verify">
              <VerifyUser auth={auth} />
            </PublicRoute>
            <ProtectedRoute auth={auth} path="/apply">
              <Apply auth={auth} />
            </ProtectedRoute>
            <PrivateRoute auth={auth} path="/portal">
              <Portal auth={auth} />
            </PrivateRoute>
            <PublicRoute path="/">
              <Login auth={auth}/>
            </PublicRoute>
          </Switch>
        </Container>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default App;