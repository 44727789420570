import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'carbon-components/css/carbon-components.css';

import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
// import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import App from './App';
// Amplify.configure(awsExports);

// Amplify.configure({
//   Auth: {
//       identityPoolId: 'ap-southeast-2:fe522c4d-e874-4406-bb15-b628a63b1161', //REQUIRED - Amazon Cognito Identity Pool ID
//       region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
//       userPoolId: 'ap-southeast-2_pTUBaJkul', //OPTIONAL - Amazon Cognito User Pool ID
//       userPoolWebClientId: '39v4qp5psk2dsnc72tum60s03m', //OPTIONAL - Amazon Cognito Web Client ID
//        // OPTIONAL - Hosted UI configuration
//       oauth: {
//           domain: 'lowie-portal.auth.ap-southeast-2.amazoncognito.com',
//           scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
//           redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
//           redirectSignOut: `${window.location.protocol}//${window.location.host}/signout`,
//           responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
//       },
//       federatedTarged: "COGNITO_USER_POOLS"
//   },
//   API: {
//     endpoints: [
//         {
//             name: "",
//             endpoint: "/api"
//         }
//     ]
//   },
//   Storage: {
//     AWSS3: {
//         bucket: 'lr-onboarding-candidate-files',
//         region: 'ap-southeast-2',
//     }
//   }
// });

let route = window.location.href
if(route.includes("#")) {
  if(!route.includes("register")) {
    localStorage.setItem("lowie-route-from", route.split("#")[1])
  }
} else {
  localStorage.setItem("lowie-route-from", "/portal")
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
