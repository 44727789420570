import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Grid, Select, MenuItem, TextField, FormControl, FormLabel, FormHelperText, Button
} from "@material-ui/core"; 
import { useForm, Controller } from "react-hook-form"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { API } from "aws-amplify";
import { Candidate, PersonalInfoPart1 } from '../../CandidateTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);
  interface Props {
    candidate?: Candidate
    activeStep: number;
    incrementActiveStep: () => void
  }

  export default function RegistrationProfilePart1({ candidate, activeStep, incrementActiveStep } : Props) {
    const classes = useStyles()
    const { handleSubmit, control, errors, setValue, reset } = useForm<PersonalInfoPart1>();

    const [isLoading, setLoading] = useState<boolean>(false);
    
    useEffect(() => {
        let jsonForm = localStorage.getItem('personalInfoPart1Form')
        if(jsonForm) {
            let form: PersonalInfoPart1 = JSON.parse(jsonForm)
            reset(form)
        } else {
            if(candidate) {
                setValue("firstName", candidate.firstName)
                setValue("surname", candidate.surname)
                setValue("title", candidate.title)
                setValue("email", candidate.email)
            }
        }

    }, [candidate, setValue]);

    const handleNext = (data: PersonalInfoPart1) => {

        setLoading(true);

        localStorage.setItem('personalInfoPart1Form', JSON.stringify(data))
        
        const params = {
            body:{
                data: data,
                step: activeStep
            }
        };
        API.post("", `/candidates/${candidate?.username}/registration`,params)
        .then(() => {
            setLoading(false);
            incrementActiveStep();
        })
        .catch((error: any) => {
            console.log("ERROR updating registration for candidate", error);
            setLoading(false);
        })
    };
  
    return (
        <div>
          <h3 style={{marginBottom: 16}}>Personal Information</h3>

            <FormControl
                className={classes.input}>

                <FormLabel className={classes.label}>Title</FormLabel>

                <Controller
                    as={
                        <Select variant="filled"
                            displayEmpty={true}
                            defaultValue=""
                            disabled>
                            <MenuItem value="">Select a Title</MenuItem>
                            <MenuItem value="Mr">Mr</MenuItem>
                            <MenuItem value="Miss">Miss</MenuItem>
                            <MenuItem value="Ms">Ms</MenuItem> 
                            <MenuItem value="Mrs">Mrs</MenuItem>
                        </Select>
                    }
                    name="title"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            <FormControl
                className={classes.input}>

                <FormLabel className={classes.label}>First Name</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter first name"
                            fullWidth
                            variant="filled"
                            disabled/>
                    }
                    name="firstName"
                    control={control}
                    defaultValue=""

                />
            </FormControl>

            <FormControl
                className={classes.input}>

                <FormLabel className={classes.label}>Surname</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter surname"
                            fullWidth
                            variant="filled"
                            disabled/>
                    }
                    name="surname"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            <FormControl
                error={Boolean(errors.gender)}
                className={classes.input}>

                <FormLabel className={classes.label}>Gender</FormLabel>

                <Controller
                    as={
                        <Select variant="filled" 
                            defaultValue=""
                            displayEmpty={true}>
                            <MenuItem value="">Select an option</MenuItem>
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    }
                    name="gender"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.gender && errors.gender.message}
                </FormHelperText>
            </FormControl>

            <FormControl
                error={Boolean(errors.dob)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Date of Birth</FormLabel>

                <Controller
                    render={({onChange, value}) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                                <KeyboardDatePicker
                                    autoOk
                                    placeholder="DD/MM/YYYY"
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker7"
                                    openTo="year"
                                    value={value}
                                    onChange={date => onChange(date)} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    )}
                    name="dob"
                    control={control}
                    defaultValue={null}
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.dob && errors.dob.message}
                </FormHelperText>
            </FormControl>
  

            <FormControl
                className={classes.input}>

                <FormLabel className={classes.label}>Email</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter email"
                            fullWidth
                            variant="filled"
                            disabled/>
                    }
                    name="email"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            <div>
                <Button variant="contained" color="primary" onClick={handleSubmit(handleNext)} disabled={isLoading}>
                    Next
                </Button>
            </div>
        </div>
    )
}
