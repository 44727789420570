import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MyAuth } from '../hooks/useAuth';
import {  
    Button, Dialog, DialogTitle, DialogContent, 
    CircularProgress, DialogActions, FormControl, FormLabel, TextField
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
        marginRight: 8,
        marginBottom: 8,
        width: 100
    },
    input: {
        marginBottom: theme.spacing(3),
        width: "100%"
    },
    label: {
        marginBottom: 6
    },
  })
);

interface ChangePasswordDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    auth: MyAuth
}

interface TextFieldError {
    error: boolean;
    message: string;
}

export default function ChangePasswordDialog({ open, handleDialog, auth }: ChangePasswordDialogProps) {

    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const [oldPassword, setOldPassword] = useState<string>("")
    const [oldPasswordError, setOldPasswordError] = useState<TextFieldError>({
        error: false,
        message: ""
    })
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordError, setNewPasswordError] = useState<TextFieldError>({
        error: false,
        message: ""
    })

    useEffect(() => {
        if(open) {
            setOldPassword("")
            setOldPasswordError({error: false, message: ""})
            setNewPassword("")
            setNewPasswordError({error: false, message: ""})
            setError(undefined)
        }
    }, [open])

    const onSubmit = () => {
        if(oldPassword === "" || newPassword === "") {
            if(oldPassword === "") {
                setOldPasswordError({
                    error: true,
                    message: "This is required"
                })
            }
    
            if(newPassword === "") {
                setNewPasswordError({
                    error: true,
                    message: "This is required"
                })
            }
        } else {
            setLoading(false)
            auth.changePassword(oldPassword, newPassword)
            .then((response) => {
                setLoading(false)
                handleDialog(false)
            })
            .catch((error) => {
                console.log("Error: changing password", error)
                setLoading(false)
                if(error.code === "NotAuthorizedException") {
                    setError("Error: old password doesn't match")
                } else if (error.code === "InvalidParameterException") {
                    setError("Error: invalid password")
                } else if (error.code === "LimitExceededException") {
                    setError("Error: limit exceeded, try again later")
                } else {
                    setError("Error: changing password")
                }
            })
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Change Password    
            </DialogTitle>
            <DialogContent>
                <p style={{marginBottom: 16}}>
                    Enter your old password and a new password.
                </p>

                <FormControl 
                    className={classes.input}
                    error={oldPasswordError.error}>
    
                    <FormLabel className={classes.label}>Old Password</FormLabel>
    
                    <TextField
                        placeholder="Enter old password"
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="password"
                        value={oldPassword}
                        onChange={(event) => {
                            setOldPasswordError({error: false, message: ""})
                            setOldPassword(event.target.value as string)
                        }}
                        error={oldPasswordError.error}
                        helperText={oldPasswordError.message}/>
                </FormControl>
                
                <FormControl 
                    className={classes.input}
                    error={newPasswordError.error}>
    
                    <FormLabel className={classes.label}>New Password</FormLabel>
    
                    <TextField
                        placeholder="Enter new password"
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="password"
                        value={newPassword}
                        onChange={(event) => {
                            setNewPasswordError({error: false, message: ""})
                            setNewPassword(event.target.value as string)
                        }}
                        error={newPasswordError.error}
                        helperText={newPasswordError.message}/>
                </FormControl>

                {error &&
                    <div style={{color: "red"}}>{error}</div>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => handleDialog(false)}>
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="primary" className={classes.actionButton}>
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => onSubmit()} variant="contained" color="primary" className={classes.actionButton}>
                        Submit
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
