import React, { useState } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Card, CardHeader, CardContent, Radio, RadioGroup, FormControl,
    FormLabel, FormControlLabel, FormHelperText, CardActions,
    Button, CircularProgress
} from "@material-ui/core"; 
import { useForm, Controller } from "react-hook-form"
import { AMPInductionQuizForm } from './AMPFormTypes';
import { API } from "aws-amplify";
import { CandidateActionsInfo } from '../../CandidateTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface Props {
    formData?: CandidateActionsInfo;
    candidateId?: string;
}

export default function AMPInductionQuizComponent({ formData, candidateId }: Props) {

    const classes = useStyles()
    const { handleSubmit, control, errors, getValues } = useForm<AMPInductionQuizForm>({
        shouldUnregister: false,
        defaultValues: {
            q1: {
                question: "I must wear the correct PPE at all times",
                answer: ""
            },
            q2: {
                question: "I must follow legal instructions from my supervisor or team leader",
                answer: ""
            },
            q3: {
                question: "I must not tell my supervisor if I think there is something that is unsafe",
                answer: ""
            },
            q4: {
                question: "I must tell my supervisor or team leader if I am involved in / see an incident",
                answer: ""
            },
            q5: {
                question: "I must use my back and bend it when picking up cartons",
                answer: ""
            },
            q6: {
                question: "I must use chemicals for cleaning, even if I have not been trained to use them",
                answer: ""
            },
            q7: {
                question: "I must look at the windsock if there is an ammonia leak & go the opposite way",
                answer: ""
            },
            q8: {
                question: "I must ignore signs on site and in my department",
                answer: ""
            },
            q9: {
                question: "I must wear a CRG (Cut resistant glove) on both hands if using a knife",
                answer: ""
            },
            q10: {
                question: "I must never smoke on site",
                answer: ""
            },
            q11: {
                question: "Are signs a risk or control?",
                answer: ""
            },
            q12: {
                question: "Are chemicals a risk or control?",
                answer: ""
            },
            q13: {
                question: "Is equipment and machinery a risk or control?",
                answer: ""
            },
            q14: {
                question: "Is PPE (Personal Proctective Equipment) a risk or control?",
                answer: ""
            },
            q15: {
                question: "Are knives a risk or control?",
                answer: ""
            },
            q16: {
                question: "Is lockout/guarding a risk or control?",
                answer: ""
            },
            q17: {
                question: "Are slippery floors a risk or control?",
                answer: ""
            },
            q18: {
                question: "Are vehicles/trucks/forklifts a risk or control?",
                answer: ""
            },
            q19: {
                question: "Are SOP's (Standard Operating Procedures) a risk or control?",
                answer: ""
            },
            q20: {
                question: "Is training a risk or control?",
                answer: ""
            },
            q21: {
                question: "Safety starts with __________.",
                answer: ""
            },
            q22: {
                question: "When a machine is __________ cannot work on it or turn it on.",
                answer: ""
            },
            q23: {
                question: "I have the right to __________ unsafe work instructions.",
                answer: ""
            },
            q24: {
                question: "If I see one of my workmates doing something that is not safe I can __________ the work so that I can make sure they make themselves, or the environment safe first before they can carry on.",
                answer: ""
            },
            q25: {
                question: "All Fire __________ must be treated as real.",
                answer: ""
            }
        }
    });

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const onSubmit = (data: AMPInductionQuizForm) => {
        if(candidateId && formData) {
            setLoading(true)

            formData.data = data
            
            let params = {
                body: {
                    id: candidateId,
                    data: formData
                }
            }
            API.post("", `/forms/submit`, params)
            .then(() => {
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: submitting form", error)
                setLoading(false)
                setError("Error: submitting form")
            })
        } else {
            setError("Error: submitting form")
        }
       
    }

    return (
        <div>
            <Card>
                <CardHeader title="AMP Induction Quiz"/>
                <CardContent>
                    <FormControl
                        error={Boolean(errors.q1?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q1.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q1.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q1?.answer && errors.q1?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q2?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q2.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q2.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q2?.answer && errors.q2?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q3?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q3.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q3.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q3?.answer && errors.q3?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q4?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q4.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q4.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q4?.answer && errors.q4?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q5?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q5.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q5.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q5?.answer && errors.q5?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q6?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q6.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q6.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q6?.answer && errors.q6?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q7?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q7.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q7.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q7?.answer && errors.q7?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q8?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q8.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q8.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q8?.answer && errors.q8?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q9?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q9.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q9.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q9?.answer && errors.q9?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q10?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q10.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="True" control={<Radio />} label="True" />
                                <FormControlLabel value="False" control={<Radio />} label="False" />
                                </RadioGroup>
                            }
                            name="q10.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q10?.answer && errors.q10?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q11?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q11.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q11.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q11?.answer && errors.q11?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q12?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q12.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q12.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q12?.answer && errors.q12?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q13?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q13.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q13.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q13?.answer && errors.q13?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q14?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q14.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q14.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q14?.answer && errors.q14?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q15?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q15.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q15.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q15?.answer && errors.q15?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q16?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q16.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q16.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q16?.answer && errors.q16?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q17?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q17.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q17.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q17?.answer && errors.q17?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q18?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q18.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q18.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q18?.answer && errors.q18?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q19?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q19.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q19.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q19?.answer && errors.q19?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q20?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q20.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Risk" control={<Radio />} label="Risk" />
                                <FormControlLabel value="Control" control={<Radio />} label="Control" />
                                </RadioGroup>
                            }
                            name="q20.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q20?.answer && errors.q20?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q21?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q21.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" />
                                <FormControlLabel value="Me" control={<Radio />} label="Me" />
                                <FormControlLabel value="Stop" control={<Radio />} label="Stop" />
                                <FormControlLabel value="Locked out" control={<Radio />} label="Locked out" />
                                <FormControlLabel value="Alarms" control={<Radio />} label="Alarms" />
                                </RadioGroup>
                            }
                            name="q21.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q21?.answer && errors.q21?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q22?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q22.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" />
                                <FormControlLabel value="Me" control={<Radio />} label="Me" />
                                <FormControlLabel value="Stop" control={<Radio />} label="Stop" />
                                <FormControlLabel value="Locked out" control={<Radio />} label="Locked out" />
                                <FormControlLabel value="Alarms" control={<Radio />} label="Alarms" />
                                </RadioGroup>
                            }
                            name="q22.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q22?.answer && errors.q22?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q23?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q23.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" />
                                <FormControlLabel value="Me" control={<Radio />} label="Me" />
                                <FormControlLabel value="Stop" control={<Radio />} label="Stop" />
                                <FormControlLabel value="Locked out" control={<Radio />} label="Locked out" />
                                <FormControlLabel value="Alarms" control={<Radio />} label="Alarms" />
                                </RadioGroup>
                            }
                            name="q23.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q23?.answer && errors.q23?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q24?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q24.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" />
                                <FormControlLabel value="Me" control={<Radio />} label="Me" />
                                <FormControlLabel value="Stop" control={<Radio />} label="Stop" />
                                <FormControlLabel value="Locked out" control={<Radio />} label="Locked out" />
                                <FormControlLabel value="Alarms" control={<Radio />} label="Alarms" />
                                </RadioGroup>
                            }
                            name="q24.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q24?.answer && errors.q24?.answer.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.q25?.answer)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>{getValues("q25.question")}</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                <FormControlLabel value="Refuse" control={<Radio />} label="Refuse" />
                                <FormControlLabel value="Me" control={<Radio />} label="Me" />
                                <FormControlLabel value="Stop" control={<Radio />} label="Stop" />
                                <FormControlLabel value="Locked out" control={<Radio />} label="Locked out" />
                                <FormControlLabel value="Alarms" control={<Radio />} label="Alarms" />
                                </RadioGroup>
                            }
                            name="q25.answer"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.q25?.answer && errors.q25?.answer.message}
                        </FormHelperText>
                    </FormControl>
                </CardContent>
                <CardActions style={{display: "flex", paddingRight: 32, paddingBottom: 32}}>
                    <div style={{flexGrow: 1}}></div>
                    <div>
                        {error &&
                            <span style={{color: "red", marginRight: 8}}>{error}</span>
                        }
                        {loading ?
                            <Button variant="contained" color="primary" style={{width: 100}}>
                                <CircularProgress size={25} style={{color: "white"}} />
                            </Button>
                        :
                            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" style={{width: 100}}>
                                Submit
                            </Button>
                        }
                    </div>
                </CardActions>
            </Card>
        </div>
    )
}
