import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Button, Card,
    Divider, LinearProgress
} from "@material-ui/core";
import { Candidate, CandidateDetailDto } from '../../CandidateTypes';
import PersonalInfoCard from './PersonalInfoCard';
import AdditionalInfoCard from './AdditionalInfoCard';
import RightToWorkCard from './RightToWorkCard';
import QualificationsCard from './QualificationsCard';
import WorkHistoryCard from './WorkHistoryCard';
import { API } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
        backgroundColor: "#ff9800"
    },
    progressBackground: {
        backgroundColor: "#ffe0b2"
    },
  })
);

interface Props {
    candidate: Candidate | undefined
}

export default function Profile({ candidate }: Props) {

    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const [candidateData, setCandidateData] = useState<CandidateDetailDto>()
    const [registrationComplete, setRegistrationComplete] = useState<boolean>(false)
    
    const [expandQualifications, setExpandQualifications] = useState<boolean>(false)
    const [expandRightToWork, setExpandRightToWork] = useState<boolean>(false)
    const [initialLoad, setInitialLoad] = useState<boolean>(true)

    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if(candidate) {
            setLoading(true)
            API.get("", `/candidates/${candidate?.username}/details`, {})
            .then((response: CandidateDetailDto) => {
                if(response) {
                    setCandidateData(response)
                    setRegistrationComplete(candidate.registrationStep === 8)
                }    
                setLoading(false)
                checkNotifications()
            })
            .catch((error: any) => {
                console.log("Error: fetching candidate details", error)
                setLoading(false)
            })
        }

    }, [candidate])

    useEffect(() => {
        if(location && expandQualifications && initialLoad) {
            if(location.search === "?q=1") {
                setTimeout(() => {
                    document?.getElementById("qualifications-form")?.scrollIntoView({ behavior: "smooth" })
                    setInitialLoad(false)
                }, 400)
            }
        }
    }, [expandQualifications])

    useEffect(() => {
        if(location && expandRightToWork && initialLoad) {
            if(location.search === "?rtw=1") {
                setTimeout(() => {
                    document?.getElementById("right-to-work-form")?.scrollIntoView({ behavior: "smooth" })
                    setInitialLoad(false)
                }, 400)
            }
        }
    }, [expandRightToWork])

    const checkNotifications = () => {
        if(location) {
            if(location.search === "?q=1") {
                setExpandQualifications(true)
            } else if (location.search === "?rtw=1") {
                setExpandRightToWork(true)
            }
        }
    }

    const getCandidateData = () => {
        if(candidate) {
            setLoading(true)
            API.get("", `/candidates/${candidate?.username}/details`, {})
            .then((response: CandidateDetailDto) => {
                if(response) {
                    setCandidateData(response)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching candidate details", error)
                setLoading(false)
            })
        }
    }

    return (
        <div style={{padding: 8}}>
            <div style={{marginBottom: 16}}>
                <h1 style={{marginBottom: 16}}>Profile</h1>
                {loading &&
                    <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}} />
                }
                <Divider />
            </div>
            {!loading &&
                <div>
                    {registrationComplete ?
                        <div>
                            <PersonalInfoCard data={candidateData?.profile} detail={candidateData?.detail} refreshCandidateData={getCandidateData}/>
                            <AdditionalInfoCard data={candidateData?.additionalInfo} candidate={candidate?.username} refreshCandidateData={getCandidateData}/>
                            <RightToWorkCard data={candidateData?.rightToWork} candidate={candidate?.username} refreshCandidateData={getCandidateData} expand={expandRightToWork} setExpanded={setExpandRightToWork}/>
                            <QualificationsCard data={candidateData?.qualifications} candidate={candidate?.username} refreshCandidateData={getCandidateData} expand={expandQualifications} setExpanded={setExpandQualifications} />
                            <WorkHistoryCard data={candidateData?.workHistory} candidate={candidate?.username} refreshCandidateData={getCandidateData}/>
                        </div>
                    :
                        <Card style={{marginTop: 32, padding: 16}}>
                            <h4>You must complete the registration process before viewing profile.</h4>
                            <Button variant="contained" color="primary" style={{marginTop: 16}} onClick={() => history.push("/registration-process/1")}>
                                Complete profile process
                            </Button>
                        </Card>
                    }
                </div>
            }
        </div>
    )
}
