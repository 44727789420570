import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {  
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, 
    CircularProgress, DialogActions
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    actionButton: {
        marginRight: 8,
        marginBottom: 8,
        width: 100
    }
  })
);

interface ConfirmDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    dialogTitle: string;
    dialogDescription: string
    onSubmit: () => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    error: string | undefined;
    setError: (error: string) => void;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    const classes = useStyles()

    return (
        <Dialog fullWidth maxWidth={'sm'} open={props.open} onClose={() => props.handleDialog(false)}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.dialogDescription}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.error &&
                    <span style={{color: "red", marginRight: 8}}>{props.error}</span>
                }
                <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => props.handleDialog(false)}>
                    No
                </Button>
                {props.loading ?
                    <Button variant="contained" color="primary" className={classes.actionButton}>
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => props.onSubmit()} variant="contained" color="primary" className={classes.actionButton}>
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
