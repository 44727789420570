import React, { useEffect } from 'react'
import { Route } from 'react-router'

interface Props {
    children: React.ReactNode
    path: string
}

export default function PublicRoute({ children, ...rest}: Props) {

    useEffect(() => {
        if(window) {
            window.scrollTo(0, 0)
        }
    }, [window])

    return (
        <Route
            {...rest}
            render={() => (
                children
            )}
        />
    )
}
