import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import "../../App.css";
import {
    Button, Card, FormControl,
    FormLabel, TextField
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { MyAuth } from "../../hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
    error: {
      '&.MuiFormHelperText-contained': {
        marginLeft: 0
      }
    },
    submit: {
      marginTop: theme.spacing(3),
      width: 100
    },
  }),
);

interface NewPasswordForm {
    email: string;
    confirmationCode: string;
    newPassword: string;
}

interface Props {
    auth: MyAuth
}

interface LocationProps {
    userEmail: string
}

export default function NewPassword({ auth }: Props) {

    const { handleSubmit, control, errors, setError } = useForm<NewPasswordForm>()
  
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation<LocationProps>()

    const [userEmail, setUserEmail] = useState<string>()

    useEffect(() => {
        if(location.state) {
            if(location.state.userEmail) {
                setUserEmail(location.state.userEmail)
            }
        } else {
            history.replace("/forgot-password")
        }
    }, [location.state])
  
    const onSubmit = (data: NewPasswordForm) => {
      if(data) {
        auth.forgotPasswordSubmit(data.email, data.confirmationCode, data.newPassword)
        .then(() => history.push("/"))
        .catch((error: any) => {
          setError("newPassword", {message: "Invalid Password"})
        })
      }
    }
  
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
        <Card style={{padding: 16, width: 650}}>
            <h3 style={{marginBottom: 32}}>New Password</h3>
  
            <p style={{marginBottom: 16}}>
              A verification code has been sent via sms. Fill in the form to create a new password.
            </p>
            
            <FormControl 
                className={classes.input}
                error={Boolean(errors.email)}>
  
                <FormLabel className={classes.label}>Email</FormLabel>
  
                <Controller
                    as={
                    <TextField
                        placeholder=""
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}/>
                    }
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                    required: "This is required"
                    }}
                />
            </FormControl>
  
            <FormControl 
                className={classes.input}
                error={Boolean(errors.confirmationCode)}>
  
                <FormLabel className={classes.label}>Code</FormLabel>
  
                <Controller
                    as={
                    <TextField
                        placeholder=""
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={errors.confirmationCode ? true : false}
                        helperText={errors.confirmationCode?.message}/>
                    }
                    name="confirmationCode"
                    control={control}
                    defaultValue=""
                    rules={{
                    required: "This is required"
                    }}
                />
            </FormControl>
  
            <FormControl 
                className={classes.input}
                error={Boolean(errors.newPassword)}>
  
                <FormLabel className={classes.label}>New Password</FormLabel>
  
                <Controller
                    as={
                    <TextField
                        placeholder=""
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="password"
                        error={errors.newPassword ? true : false}
                        helperText={errors.newPassword?.message}/>
                    }
                    name="newPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                    required: "This is required"
                    }}
                />
            </FormControl>

            <a href="#" onClick={(event) => {
                event.preventDefault()
                console.log(userEmail)
                if(userEmail) {
                    auth.forgotPassword(userEmail)
                }
            }}>
                Resend Code
            </a>
  
            <div style={{display: "flex", marginTop: 16}}>
                <div style={{flexGrow: 1}}></div>
                <Button className={classes.submit} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                    Submit
                </Button>
            </div>
        </Card>
      </div>
    )
  }